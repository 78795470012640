export function  userReducer(state = {type:"",login:false,data:{},message:""}, action){
  let obj;
  let tmp = [];
   obj = {...state};
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "RESET_USER":
      return  {type:"",login:false,data:{},message:""}
    case "LOGIN_REQUEST":
      return {...state,type:"request",mintType:action.mintType}   
    case "LOGIN_RESET":
      return {type:"",login:false,data:{},message:""}
    case "UPDATE_EMAIL_REQUEST":
      return {...obj,type:"email_empty",forceEmail:true}
    case "LOGIN_SUCCESS":
      return {type:"success",login:true,data:action.data,message:"",mintType:action.mintType}
    case "UPDATE_USER_REQUEST":
      return {...obj, type:"update_request"}
    case "UPDATE_USER_SUCCESS":
      return {...obj,type: "success", data: action.data, message: ""}
    case "UPDATE_USER_ERROR":
      return {...obj, type: "update_error", data: action.data, message: action.message}
    case "LOGIN_ERROR":
      return {type:"error",login:false,data:{},message:action.message}

      
    case "UPDATE_PAGE_SETTINGS_REQUEST":
      return {...obj, type:"update_request"}
    case "UPDATE_PAGE_SETTINGS_SUCCESS":
      return {...obj,type: "success", data: action.data, message: ""}
    case "UPDATE_PAGE_SETTINGS_ERROR":
      return {...obj, type: "update_error", message: action.message}
  }
  return state;
}
