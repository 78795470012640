// for making helper functions

import moment from "moment";
import ReactGA from "react-ga4";


export function ValidateEmail(email) 
{
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}



 export async function   copyText(text){
  await window.navigator.clipboard.writeText(text); 
 }



 export function showUTCTime(date_time){
    //   Fri, December 10th 15:00
    return  moment.utc(date_time).format('ddd, MMMM Do HH:mm');
 }

 export function showUTCTime2(date_time){
    //   December 10th, 2022
    return  moment.utc(date_time).format('MMMM Do, YYYY');
 }

 export function showUTCTime3(date_time){
    //   Friday 11/10
    return  moment.utc(date_time).format('LL');
  }
 

 export function addToCalendar(drop){
    let url =`https://calendar.google.com/calendar/render?action=TEMPLATE&text=PX Drop - ${encodeURI(drop.dropName)}&dates=${encodeURI(drop.drp_date_time_utc.replace(/-/g, '').replace(/:/g, ''))}/${encodeURI(drop.drp_date_time_utc.replace(/-/g, '').replace(/:/g, ''))}&details=${encodeURI(drop.about_sponsor)}`;
    //  let url =`https://calendar.google.com/calendar/render?action=TEMPLATE&text=PX Drop&dates=${encodeURI(drop.drp_date_time_utc)}/${encodeURI(drop.drp_date_time_utc)}&details=With%20clowns%20and%20stuff&location=North%20Pole`
    window.open(url);
 }


export const GPageView = (title,path)=>{
    if(window.gtag){ 
      window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {'page_title' : title, 'page_path': path});
    }
  }
  
  export const GPageEvent = (name, label, category)=>{
    if(window.gtag){ 
      window.gtag('event', name, {
        'event_label': label,
        'event_category': category,
        'non_interaction': true
      }); 
    }
  }
  
  
  export const GScreenView = (screen_name)=>{
    if(window.gtag){ 
      window.gtag('event', 'screen_view', {
        'app_name': 'Ape Island Season 2',
        'screen_name' : screen_name
      });
    }
  }

export const strTosnakeCase = (str) => {
  return str.replace(/\s/g, '_').toLowerCase();
};

export const trimStr = (string, length) => {
  if(typeof string =='undefined' || string ==null){
    return "";
  }
  return string.length > length ? 
         string.substring(0, length) + '...' :
         string;
}

export const snakeCaseToCamels = (str) => {
  return str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
};

export const converDateToRemainingDays = (date) => {
  // if (date == null || date == '') {return null}
  let current_dt = new Date(moment());
  let dt = new Date(moment(date));
  let enddt = moment(date)

  let diff = Math.floor((dt - current_dt) / 86400000);
  let start = moment()
  var end = moment().add(diff, 'days');

  var years = end.diff(start, 'year');
  start.add(years, 'years');

  var months = end.diff(start, 'months');
  start.add(months, 'months');

  var week = end.diff(start, 'week');
  start.add(week, 'week');

  var duration = moment.duration(enddt.diff(start));
  var hours = duration.asHours();
  var minutes = parseInt(duration.asMinutes()) % 60;
  var seconds = parseInt(duration.asSeconds()) % 60;

  var days = end.diff(start, 'days');
  start.add(days, 'days');

  if(years > 0){
    return (years == 1 )? years + ' Year': years + ' Years'
  }
  else if(months > 0 ){
    return (months == 1)? months+ ' Month': months+ ' Months'
  }
  else if(week > 0){
    return (week == 1) ?  week + ' Week': week + ' Weeks'
  }
  else if(days > 0){
    return (days == 1) ? days + ' Day' : days + ' Days'
  }else if(days == 0 && parseInt(hours) > 0){
    return (parseInt(hours) == 1)?(parseInt(hours))+ ' Hour':  (parseInt(hours))+ ' Hours'
  }
  else if(parseInt(hours) == 0 && minutes > 0){
    return (minutes == 1) ?minutes + ' Minute': minutes + ' Minutes'
  }else if(parseInt(minutes) == 0 && seconds > 0){
    return (seconds == 1) ?seconds + ' Second': seconds + ' Seconds'
  }else{
    return ''
  }
}

export function viewUTCTime(date_time){
    return new Date(date_time).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
}

export const viewDate = (date) => {
  let d = new Date(date);
  let month = (d.getMonth() + 1).toString();
  let day = d.getDate().toString();
  let year = d.getFullYear();
  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }
  // return [year, month, day].join('-');
  return `${month}/${day}/${year}`
}

export const viewDateTime = (date) => {
  try{
    var now = new Date(date);
    /* var dateStr =
      ("00" + (now.getMonth() + 1)).slice(-2) + "/" +
      ("00" + now.getDate()).slice(-2) + "/" +
      now.getFullYear() + " " +
      ("00" + now.getHours()).slice(-2) + ":" +
      ("00" + now.getMinutes()).slice(-2); */
    return now.toLocaleString('en-US', { hour12: true })
  } catch (e) {
    return date
  }
}

export const formatDateToDbSup = (date) => {
  try{
    var now = new Date(date);
    var offset = now.getTimezoneOffset() * 60000;
    var adjustedDate = new Date(now.getTime() - offset);
    var formattedDate = adjustedDate.toISOString().substring(0,16); // For minute precision
    return formattedDate
  } catch (e) {
    return date
  }
}

export const formatNum = (num) => {
  let nf = new Intl.NumberFormat('en-US');
  return nf.format(num) == "NaN" ? "N/A" : nf.format(num)
}

export const isValidHttpUrl = (string) => {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const dropTypesData = [
  {
    icon: "/assets/images/coupon.svg",
    img_src: "/assets/images/couponcode.svg",
    title: "Coupon Code",
    short_name: "coupon_code",
    description: "Offer discounts or free products from an e-commerce store."
  },
  {
    icon: "/assets/images/gated.svg",
    img_src: "/assets/images/gatedcontent.svg",
    title: "Gated Content",
    short_name: "gated_content",
    description: "Give exclusive access to digital content like music, video or PDFs."
  },
  {
    icon: "/assets/images/pass.svg",
    img_src: "/assets/images/eventpass.svg",
    title: "Event Pass",
    short_name: "event_pass",
    description: "Create an event pass for a live event and check in attendees at the door."
  },
  {
    icon: "/assets/images/list.svg",
    img_src: "/assets/images/allowlist.svg",
    title: "Allow List",
    short_name: "allow_list",
    description: "Give exclusive access to an upcoming NFT mint."
  },
  {
    icon: "/assets/images/nft.svg",
    img_src: "/assets/images/nfts.svg",
    title: "NFTs",
    short_name: "nfts",
    description: "Give NFTs like art, game items, and digital goods."
  },
  {
    icon: "/assets/images/token.svg",
    img_src: "/assets/images/rewardtokens.svg",
    title: "Reward Tokens",
    short_name: "reward_tokens",
    description: "Create reward tokens used to redeem benefits in other web3 applications."
  },
  {
    icon: "/assets/images/merchandise.svg",
    img_src: "/assets/images/merch.svg",
    title: "Merchandise",
    short_name: "merchandise",
    description: "Offer physical merchandise like t-shirts, plushies or any other item you can self-fulfill."
  },
];
 
export function gaEvent(data){
  // ReactGA.event({...data});
}

export function gtag(event_name,data){
  if(data.eth_address){
    data.eth_address ="-"+data.eth_address+"-";
  }
  ReactGA.gtag('event',event_name,data);
}

export function convertToInternationalCurrency (value) {
    if(value>=1000000)
    {
        value=+parseFloat((value/1000000).toFixed(1)) +"M"
    }
    else if(value>=1000)
    {
        value=parseFloat((value/1000).toFixed(1)) +"K";
    }
    return value;
}

export const ahex_to_rba = (ahex) => {
  //clean #
  if (ahex == "" || ahex == null || ahex == undefined) {
    return;
  }
  ahex = ahex.substring(1, ahex.length);
  ahex = ahex.split("");

  var r = ahex[0] + ahex[0],
    g = ahex[1] + ahex[1],
    b = ahex[2] + ahex[2],
    a = ahex[3] + ahex[3];

  if (ahex.length >= 6) {
    r = ahex[0] + ahex[1];
    g = ahex[2] + ahex[3];
    b = ahex[4] + ahex[5];
    a = ahex[6] + (ahex[7] ? ahex[7] : ahex[6]);
  }

  var int_r = parseInt(r, 16),
    int_g = parseInt(g, 16),
    int_b = parseInt(b, 16),
    int_a = parseInt(a, 16);

  int_a = int_a / 255;

  if (int_a < 1 && int_a > 0) int_a = int_a.toFixed(2);

  if (int_a || int_a === 0) return "rgba(" + int_r + ", " + int_g + ", " + int_b + ", " + int_a + ")";
  return int_r + ", " + int_g + ", " + int_b;
};

export const validatePhone = (input) => {
  // Basic phone pattern (like (123) 456-7890 or 123-456-7890)
  const phonePattern = /^(\+\d{1,2}\s?)?1?\-?\.?\(?\d{3}\)?[\-.\s]?\d{3}[\-.\s]?\d{4}$/;

  return phonePattern.test(input);
};
