import React, { useState } from "react";
import {Modal, Box} from '@mui/material';
import { connect } from "react-redux";
import { userLogin,userCustomLogin, userReset, userWalletAuth } from '../../services/actions/ethereum';
import CustodialWallet from "../wallet";


const LoginOptionModal = (props) => {
  const {open, handleClose, user, page, viewType, setAllowMinting} = props;
  const [iframeLoader, setIframeLoader] = useState(true)

  const style = {
    fontFamily: 'General Sans !important',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#fff",
    boxShadow: "0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32)",
    p: 0,
    borderRadius: "15px",
    zIndex: 122,
  };

  const handleAuth = (data) => {
    if (user.type !== "request") {
      handleClose(true);
      setAllowMinting(true);
      props.userReset();
      const finalUserData = {...data}
      if (finalUserData.wallet_type === 'mobile') {
        finalUserData['phone'] = data.phone
        finalUserData['email'] = null
      }

      if (finalUserData.wallet_type === 'email') {
        finalUserData['phone'] = null
        finalUserData['email'] = data.email
      }
      if (data.event) {
        props.userCustomLogin(data.eth_address, data.signature, data.profile_img, data.email, viewType, finalUserData);
      } else {
        props.userWalletAuth(finalUserData, viewType);
      }
    }
  };
  
  return(
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="registration-page">
          <>
            <CustodialWallet
              signMessage={`Welcome to Texas Eclipse 2024!\n\nSigning this message will not trigger a blockchain transaction, cost fees or allow us to transfer contents from your wallet.`}
              handleAuth={(data, cb) => {
                handleAuth(data, cb);
              }}
              title={props.modalTitle}
              wallets={["Phone", "Email", "Google", "Apple", "Web3 wallets"]}
              colors={{ bg: "#ffffff", pm: "#22185F", pm_text: "#ffffff", ac: "#ffffff" }}
            />
          </>
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page
})

const mapDispatchToProps = {
  userLogin,
  userCustomLogin,
  userWalletAuth,
  userReset
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginOptionModal)