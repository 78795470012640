import React, {useState,useEffect} from 'react'
// import { connect } from 'react-redux'


import AppBar from '@mui/material/AppBar';
import {Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Button, Container, Avatar, Link, ListItemIcon, Collapse} from '@mui/material';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import HeaderUserbox from '../Userbox';
import LoginOptionModal from '../modals/login';
import { connect } from 'react-redux';
import {shortEthAddress, shortEmailAddress} from "../../utils/ethereum"
import { logoutUser } from '../../services/actions';

import MintedCongrats from '../../component/modals/congrats'


const drawerWidth = 240;
const navItems = [{link: 'https://polyientx.com/', val: 'Event Website'}, {link:'/view-nft', val: 'View Your NFT'}, {link:'/', val: 'Mint'}];



function Header(props) {
  let location = useLocation();
  let navigate = useNavigate();
  const { window, user, page } = props;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);


  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(()=>{
    if(user.type === 'success' && user.data.mint_type==='view' && user.data.token === false){
      setShowModal(true);
    }
    if(user.type == 'success' && user.data.token !== false){
      navigate("/view-nft", {state: {showModal: user.data.mint_type !='view'}})
    }
  },[user])

  const handleMenuClick = () => {
    setOpen(!open);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleViewNftClick = () => {
    if(user.login){
      if(user.type === 'success' && user.data.mint_type==='view' && user.data.token === false){
        setShowModal(true);
      }
      if(user.type == 'success' && user.data.token !== false){
        navigate("/view-nft", {state: {showModal: user.data.mint_type !='view'}})
      }
    }else{
      if(user.type != 'request'){
        setShowLoginModal(true);
      } 
     
    }

  }

  const handleLogout = async () => {
    props.logoutUser();
  };

  const handleRootClick = () => {
    user.login ? navigate('/view-nft', {state: {showModal: false}}) : navigate("/")
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, display: "flex", justifyContent: "center", borderBottom: '1px solid rgba(255, 255, 255, 0.15)', pb: 2 }}><Link component={Button} onClick={handleRootClick}><img src='/assets/te_logo_new.svg' style={{width: "190px", height: "100%"}} /></Link></Typography>
      <Divider />
      <List>
        {
          !user.login &&
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} href="/" disableRipple>
              <ListItemText sx={{ color: '#fff', fontSize: '20px', px: 2.5, opacity: location.pathname == '/' ? 1 : 0.8, fontFamily: (location.pathname == '/' ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'),textDecoration: 'none'}} primary={"MINT"} />
            </ListItemButton>
          </ListItem>
        }
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} target="_blank" href="https://www.texaseclipse.xyz/">
            <ListItemText sx={{color: '#fff', fontSize: '20px', px: 2.5, textDecoration: 'none', opacity: 0.8, fontFamily: 'Sweet Sans Pro Regular'}} primary={"Event Website"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={handleViewNftClick}>
            <ListItemText sx={{ color: '#fff', fontSize: '20px', px: 2.5, opacity: location.pathname == '/view-nft' ? 1 : 0.8, fontFamily: (location.pathname == '/view-nft' ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'), textDecoration: 'none'}} primary={"View Your NFT"} />
          </ListItemButton>
        </ListItem>
        
          {
            user.login &&
            <>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText sx={{ color: '#D3904F', fontSize: '20px', px: 2.5, opacity: location.pathname == '/' ? 1 : 0.8, fontFamily: (location.pathname == '/' ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'), wordBreak: "break-all",textDecoration: 'none'}} primary={ user.data?.email == null ? shortEthAddress(user.data.eth_address) : shortEmailAddress(user.data.email)} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }} disableRipple onClick={handleLogout}>
                  <ListItemText sx={{ color: '#D3904F', fontSize: '20px', px: 2.5, opacity: location.pathname == '/' ? 1 : 0.8, fontFamily: (location.pathname == '/' ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'),textDecoration: 'none'}} primary="Log Out" />
                </ListItemButton>
              </ListItem>
            </>
          }
      </List>

    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar component="nav" sx={{position: "relative", height: "108px", justifyContent: "center", paddingLeft: page.width >= 768 ? '2.25' : '0', paddingRight: page.width >= 768 ? '2.25' : '0'}}>
          <Toolbar >
            <Box justifyContent="space-between" width="100%" sx={{ display: { xs: 'flex', md: 'none' } }}>
              <Link component={Button} onClick={handleRootClick} sx={{cursor: 'default'}}><img src='/assets/te_logo_new.svg'  style={{width: "90px", height: "100%"}}/></Link>
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { xs: 'block', md: 'none' } }}>
                <MenuIcon sx={{color: "white"}}/>
              </IconButton>
            </Box>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}>
              <Link component={Button} onClick={handleRootClick} disableRipple sx={{cursor: 'default', py: '10px'}}><img src='/assets/te_logo_new.svg' style={{width: '190px', height: '100%'}} /></Link>
            </Typography>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {
                !user.login &&
                <RouterLink to="/" style={{textDecoration: 'none'}}><Button disableRipple sx={{ color: '#fff', fontSize: '20px', px: 2.5, opacity: (location.pathname == '/' &&  !((user.type =='request' && user.mintType=='view'))) ? 1 : 0.8, fontFamily: ((location.pathname == '/' &&  !((user.type =='request' && user.mintType=='view'))) ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'),textDecoration: 'none'}}>MINT</Button></RouterLink>
              }
              <Button disableRipple component={Link} target="_blank" href="https://www.texaseclipse.xyz/" sx={{ color: '#fff', fontSize: '20px', px: 2.5, textDecoration: 'none', opacity: 0.8, fontFamily: 'Sweet Sans Pro Regular'}}>Festival</Button>
               
              <Button onClick={handleViewNftClick} disableRipple sx={{ color: '#fff', fontSize: '20px', px: 2.5, opacity: (location.pathname == '/view-nft' || (user.type =='request' && user.mintType=='view')) ? 1 : 0.8, fontFamily: ((location.pathname == '/view-nft' || (user.type =='request' && user.mintType=='view')) ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'), textDecoration: 'none'}}>View Your NFT </Button>
              {
                user.login &&
                <HeaderUserbox user={{}}/>
              }
            </Box>
          </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer container={container} variant="temporary" open={mobileOpen} anchor="right" onClose={handleDrawerToggle} ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "black" },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {
        showLoginModal &&
        <LoginOptionModal open={showLoginModal} handleClose={() => setShowLoginModal(false)} modalTitle="Sign In To View" viewType="view" />
      }

      {
        (user.data.mint_type=='view' && user.data.token=== false && showModal) && 
          <MintedCongrats open={showModal} handleClose={() => setShowModal(false)} type="not_nft"/>
        }
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page
})

const mapDispatchToProps = {
  logoutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
