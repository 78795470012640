import React, {useEffect, useState} from 'react'
import { Box, Card, Typography, Container, Alert, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CWalletCard from './walletCard';
import MetaMask from './walletCard/metamask';
import WalletConnect from './walletCard/walletconnect';
import Coinbase from './walletCard/coinbase';

function ChooseWallet(props) {
  const { setStep, handleAuth, walletResponse, setWalletResponse, signMessage, theme } = props;
  const [walletType, setWalletType] = useState("")

  const resetWalletResponse = () => {
    setWalletResponse({type: '', data: {}, message: ''});
  }
  
  useEffect(() => {
    if (walletResponse.type === 'success') {
      handleAuth(walletResponse.data, (res) => {
        if (res.type === 'error') {
          setStep("1");
          setWalletResponse({...res, type: 'werror'})
        }
      })
    }
  },[walletResponse])

  return (
    <Card sx={{borderRadius: "15px", border: "1px solid #000", background: "#fff", boxShadow: "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",  width: "462px",maxWidth: "90vw", boxSizing: "border-box"}}>
      <Box sx={{p: {md: "20px 40px", xs: "20px"}, borderBottom: "1px solid rgba(0, 0, 0, 0.10)"}}>
        <Box display="flex" alignItems="center">
          <KeyboardArrowLeftIcon sx={{color: "#000",width: "1.2em", height: "1.2em",  cursor: walletResponse.type === "request" ? "default" : "pointer"}} onClick={() => {
            if(walletResponse.type !== "request"){
              resetWalletResponse();
              setStep("1")
            }
          }}/>
          <Typography variant='h4' align='center' sx={{pr: "20px",width: "100%", color: "#000",fontSize: "1rem", lineHeight: "normal"}} className='font-gs-sb'>Choose a wallet</Typography>
        </Box>
      </Box>
      <Box sx={{pb: {md: "50px"}, px: {md: "40px"}, pt: "20px", p: {xs: "20px"}}}>
        <MetaMask signMessage={signMessage} walletResponse={walletResponse} setWalletResponse={setWalletResponse} setWalletType={setWalletType} walletType={walletType} theme={theme}/>
        <Coinbase signMessage={signMessage} walletResponse={walletResponse} setWalletResponse={setWalletResponse} setWalletType={setWalletType} walletType={walletType} theme={theme}/>
        <WalletConnect signMessage={signMessage} walletResponse={walletResponse} setWalletResponse={setWalletResponse} setWalletType={setWalletType} walletType={walletType} theme={theme}/>


        {walletResponse.type === "error" && (
          <Box>
            <Alert
              
              severity="error"
              action={
                <IconButton aria-label="close" color="inherit" size="small" onClick={() => { resetWalletResponse(); }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mt: '20px', color: "#FF1943", fontFamily: "Poppins, sans-serif" }}
            >
              {walletResponse.message}
            </Alert>
          </Box>
        )}
      </Box>

    </Card>
  )
}

export default ChooseWallet