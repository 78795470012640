import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

// let default_sign = {"time_stamp":1659962738805,"signature":"0xbfb020073b5840cd1bddafb73becfb8d66e582715b98e8dc6564875082ef43680ce4b25767525c830c204baf508a171076181691744f63f48e6424c109d70ef61c"};
// axios.defaults.headers={...axios.defaults.headers,...default_sign};

axios.interceptors.response.use(undefined,(err) => {
  if(err.response.status == 400 || 401 || 403){
    return err.response
  }
})

export function setPageArea(){
  return async dispatch => {
    dispatch({
      type: "PAGE_SET_AREA",
      data:{
        width: window.innerWidth,
        height: window.innerHeight
      }
    }) 
  }
}

export async function loginUser(data){
  let user_obj = await axios.post('/api/client/login', data)
    axios.defaults.headers={...axios.defaults.headers, 'time_stamp': data.time_stamp, 'signature': data.signature};
    return user_obj.data;
}

export  function logoutUser(){
  return async dispatch => {
    axios.defaults.headers={...axios.defaults.headers, 'time_stamp': "", 'signature': ""};
    dispatch({type:"RESET_USER"});
  }
}