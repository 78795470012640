import React from 'react'
import Header from '../component/header'
import HeaderNew from '../component/header/new'

import { Box, Container, Toolbar } from '@mui/material';
import Footer from '../component/footer';

export const MainAppLayout = (props) => {
  return (
    <>
      <Header/>
      {/* <Box component="main" sx={{ pt: 3, px: props.page.width >= 768 ? "3.125rem" : 3 }}> */}
        {/* <Toolbar /> */}
        {/* <Container maxWidth="lg" sx={{padding: '0px !important'}}> */}
          <Box>{props.children}</Box>
          <Footer expanded_footer={false}/>
        {/* </Container>        */}
      {/* </Box> */}
    </>
  )
}

export const NewAppLayout = (props) => {
  return (
    <>
      <HeaderNew/>
      {/* <Box component="main" sx={{ pt: 3, px: props.page.width >= 768 ? "3.125rem" : 3 }}> */}
        {/* <Toolbar /> */}
        {/* <Container maxWidth="lg" sx={{padding: '0px !important'}}> */}
          <Box>{props.children}</Box>
      <Footer expanded_footer={true}/>
        {/* </Container>        */}
      {/* </Box> */}
    </>
  )
}