import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Avatar, Button, Alert, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';


import { userReset } from '../../services/actions';
import LoginOptionModal from '../../component/modals/login';
import  { Navigate, useNavigate } from 'react-router-dom';
import { getBalanceInfo } from '../../utils/ethereum';

import { mintNft } from '../../services/actions/ethereum';


const Home = (props) => {
  const {user, page} = props;
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const [mintBalance, setMintBalance] = useState();
  const mintBalanceData = async() => {
    const bal = await getBalanceInfo();
    setMintBalance(bal)
  }
  useEffect(()=>{
    mintBalanceData()
  },[])



  const loginWallet =()=>{
    if(user.type=='success'){
      props.mintNft(user.data.eth_address,user.data.time_stamp,user.data.signature,user.data.wallet_type,'mint');
    }else{
      setShowLoginModal(true)
    }
  }

  return (
    <Box sx={{ mb: 1, mt: '3.125rem' }}>
      { (user.type =='request' && user.mintType=='view') ?
       <Box display="flex" flexDirection="column" justifyContent="center" width="100%" sx={{height:`calc(90vh - 200px)`,color:"#fff"}} alignItems="center">
       Loading your NFT...
     </Box>
     :
      <Grid container alignItems="start">
        
          <Grid item xs={12} sm={12} md={12} lg={4.5}>
            <Avatar src="/assets/banner.gif" variant="square" sx={{width: '100%', height: '100%', objectFit: "contain"}} />
          </Grid>
        
        
          <Grid item xs={12} sm={12} md={12} lg={6.5} ml={page.width >= 995 ? "3.75rem" : 0} mt={page.width >= 995 ? 0 : 3}>
            
            <Typography variant="h5" color="#fff" sx={{opacity: "0.8"}} fontSize="24px">{mintBalance} Minted</Typography>
            <Typography variant="h1" my={2} fontSize="42px" fontFamily="Sweet Sans Pro Heavy">Texas Eclipse Mint Page</Typography>
            <Typography sx={{lineHeight: "1.375rem"}} variant="body1" fontSize="1.125rem" fontWeight="400" mb={"1.375rem"} color="#fff">
            Texas Eclipse is a once-in-a-lifetime celebration of music, art, space, and technology during the 2024 total solar eclipse in Burnet, Texas!</Typography><Typography sx={{lineHeight: "1.375rem"}} variant="body1" fontSize="1.125rem" fontWeight="400" mb={"1.375rem"} color="#fff">The festival on April 5-9th, will feature space, technology and Web3-themed activations from leading innovators as well as Austin music, art and culture.</Typography><Typography sx={{lineHeight: "1.375rem"}} variant="body1" fontSize="1.125rem" fontWeight="400" mb={"1.375rem"} color="#fff">Here you can mint the free, commemorative collectible that will allow holders to participate in the community and earn rewards for the event, including merch, festival passes, and unique experiences.</Typography><Typography sx={{lineHeight: "1.375rem"}} variant="body1" fontSize="1.125rem" fontWeight="400" mb={5} color="#fff">This is a totally free transaction! That means we cover any gas costs. You can mint by simply logging in with your Apple or Google account. Or if you want to use your own wallet, please make sure to switch over to the Polygon network.
            </Typography>
            
            {
              user.type =='request' && user.mintType=='mint' ?
              <Button variant="contained" nolinkstyle="true" disableRipple={true} disabled={true} onClick={() => {loginWallet(true)}} sx={{padding: "10px 0px",width:"21rem", "&:disabled": {color: '#cacaca', backgroundColor: '#071612'}}}><img src="/assets/polygon-matic-logo.svg" alt="Polygon matic logo" style={{marginRight: "11px"}} /> Minting...</Button>
              :
              <Button variant="contained" nolinkstyle="true" disableRipple disabled={user.type =='request'} onClick={() => {loginWallet(true)}} sx={{padding: "10px 0px",width:"21rem",fontFamily: "Sweet Sans Pro Bold", "&:disabled": {color: '#cacaca', backgroundColor: '#071612'}}}><img src="/assets/polygon-matic-logo.svg" alt="Polygon matic logo" style={{marginRight: "11px"}} />Mint Your Free NFT </Button>
            }
            
            <Box mt="50px">
              <Button LinkComponent={Link} href="https://twitter.com/texas_eclipse" target="_blank" sx={{m: 0, p: 0}}>
                <Avatar src="/assets/twitter.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
              </Button>
              <Button LinkComponent={Link} href="https://discord.gg/5BqZRFxtKD" target="_blank" sx={{m: 0, p: 0}}>
                <Avatar src="/assets/discord.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
              </Button>   
              <Button LinkComponent={Link} href="https://opensea.io/collection/texas-eclipse" target="_blank" sx={{m: 0, p: 0}}>
                <Avatar src="/assets/opensea_icon.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
              </Button>
              <Button LinkComponent={Link} href="https://polygonscan.com/token/0xd82dc82d472b9fefb526df788db59e2a61366277" target="_blank" sx={{m: 0, p: 0}}>
                <Avatar src="/assets/polygon-matic-logo-2.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
              </Button>
            </Box>
            {
              user.message!="" &&
              <Box mt={2}>
                <Alert severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        props.userReset();
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{ mb: 2 }}
                >
                  {user.message}
                </Alert>
              </Box>
            }
            {
              showLoginModal &&
              <LoginOptionModal open={showLoginModal} handleClose={() => setShowLoginModal(false)} modalTitle="Mint Your Free NFT" viewType="mint" />
            }

          </Grid>
      </Grid>
      }
    </Box>
  );
}
const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page
})

const mapDispatchToProps = {
  userReset,
  mintNft
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)