import React, { useState, useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material';
import { connect, useSelector } from 'react-redux';

const IframeWallet = (props) => {
  const { setWalletClicked, walletClicked, signMessage, theme,setWalletResponse, page } = props;
  const [iframeLoader, setIframeLoader] = useState(true);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [user, setUser] = useState({ type: "", data: {} });
  let isMobile = false;

  const onFrameLoad = () => {
    setIframeLoader(false);
    const iframe = document.getElementById("wallet_frame");
    let message = signMessage;
    let memberInfo = {
      name: ``,
      logo: "",
      bg_color: theme.data.base_bg_color,
      txt_color: theme.data.base_bg_text_color,
      btn_color: theme.data.base_pm_color,
      btn_text_color: theme.data.base_pm_text_color,
    };

    setTimeout(() => {
      if (iframe?.contentWindow) {
        iframe.contentWindow.postMessage({ event: "sign_data", member: memberInfo, message: message }, "*");
      }
    }, 1000);
  };

  useEffect(() => {
    let fun = (e, a, b, c) => {
      var key = e.message ? "message" : "data";
      var data = e[key];
      if (data && data.event && data.event == "iframe_resize") {
        if (data.frameType == "bigger") {
          setIsVerifyEmail(true);
        } else {
          setIsVerifyEmail(false);
        }
      }
      if (data && data.event && data.event == "wallet_clicked") {
        // console.log("data.iframeBtnClickType =>",data.iframeBtnClickType)
        setWalletClicked(data.iframeBtnClickType)
      }
      if (data && data.event && data.event == "sign_user_px") {
        let eth_address = data.eth_address;
        let signature = data.signature;
        console.log("social data =>",data)
        setWalletResponse({
          type: "success",
          data
        });
        // setUser({type: 'success', data: data})
      }
    };
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, fun);

    return () => {
      window.removeEventListener(messageEvent, fun);
    };
  }, []);

  return (
    <Box sx={{ mt: 3 }}>
      {iframeLoader && (
        <Box my={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <iframe
        id="wallet_frame"
        src={`${process.env.REACT_APP_BASE_APPLE_URL}/social`}
        onLoad={() => {
          onFrameLoad();
        }}
        width="100%"
        title="Social Sign In"
        frameBorder="0"
        style={{ height: isMobile ? '138px' : '135px', overflow: "hidden", display: iframeLoader ? "none" : "inline-block" }}
      />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  page: state.page
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(IframeWallet)
