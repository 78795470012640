import React, {useState,useEffect} from 'react'
// import { connect } from 'react-redux'


import AppBar from '@mui/material/AppBar';
import {Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography, Button, Container, Avatar, Link, ListItemIcon, Collapse} from '@mui/material';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import HeaderUserbox from '../Userbox';
import LoginOptionModal from '../modals/login';
import { connect } from 'react-redux';
import {shortEthAddress, shortEmailAddress} from "../../utils/ethereum"
import { logoutUser } from '../../services/actions';

import MintedCongrats from '../../component/modals/congrats'


const drawerWidth = 240;
const navItems = [{link: 'https://polyientx.com/', val: 'Event Website'}, {link:'/view-nft', val: 'View Your NFT'}, {link:'/', val: 'Mint'}];



function HeaderNew(props) {
  let location = useLocation();
  let navigate = useNavigate();
  const { window, user, page } = props;
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);


  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(()=>{
    if(user.type === 'success' && user.data.mint_type==='view' && user.data.token === false){
      setShowModal(true);
    }
    if(user.type == 'success' && user.data.token !== false){
      navigate("/view-nft", {state: {showModal: user.data.mint_type !='view'}})
    }
  },[user])

  const handleMenuClick = () => {
    setOpen(!open);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleViewNftClick = () => {
    if(user.login){
      if(user.type === 'success' && user.data.mint_type==='view' && user.data.token === false){
        setShowModal(true);
      }
      if(user.type == 'success' && user.data.token !== false){
        navigate("/view-nft", {state: {showModal: user.data.mint_type !='view'}})
      }
    }else{
      if(user.type != 'request'){
        setShowLoginModal(true);
      } 
     
    }

  }

  const handleLogout = async () => {
    props.logoutUser();
  };

  const handleRootClick = () => {
    user.login ? navigate('/view-nft', {state: {showModal: false}}) : navigate("/")
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, display: "flex", justifyContent: "center", borderBottom: '1px solid rgba(255, 255, 255, 0.15)', pb: 2 }}><Link component={Button} onClick={handleRootClick}><img src='/assets/te_logo_new.svg' style={{width: "190px", height: "100%"}} /></Link></Typography>
      <Divider />
      <List>
        {
          !user.login &&
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} href="/" disableRipple>
              <ListItemText sx={{ color: '#fff', fontSize: '20px', px: 2.5, opacity: location.pathname == '/' ? 1 : 0.8, fontFamily: (location.pathname == '/' ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'),textDecoration: 'none'}} primary={"MINT"} />
            </ListItemButton>
          </ListItem>
        }
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} target="_blank" href="https://www.texaseclipse.xyz/">
            <ListItemText sx={{color: '#fff', fontSize: '20px', px: 2.5, textDecoration: 'none', opacity: 0.8, fontFamily: 'Sweet Sans Pro Regular'}} primary={"Event Website"} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: 'center' }} onClick={handleViewNftClick}>
            <ListItemText sx={{ color: '#fff', fontSize: '20px', px: 2.5, opacity: location.pathname == '/view-nft' ? 1 : 0.8, fontFamily: (location.pathname == '/view-nft' ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'), textDecoration: 'none'}} primary={"View Your NFT"} />
          </ListItemButton>
        </ListItem>
        
          {
            user.login &&
            <>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText sx={{ color: '#D3904F', fontSize: '20px', px: 2.5, opacity: location.pathname == '/' ? 1 : 0.8, fontFamily: (location.pathname == '/' ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'), wordBreak: "break-all",textDecoration: 'none'}} primary={ user.data?.email == null ? shortEthAddress(user.data.eth_address) : shortEmailAddress(user.data.email)} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }} disableRipple onClick={handleLogout}>
                  <ListItemText sx={{ color: '#D3904F', fontSize: '20px', px: 2.5, opacity: location.pathname == '/' ? 1 : 0.8, fontFamily: (location.pathname == '/' ? 'Sweet Sans Pro Heavy' : 'Sweet Sans Pro Regular'),textDecoration: 'none'}} primary="Log Out" />
                </ListItemButton>
              </ListItem>
            </>
          }
      </List>

    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar component="nav" sx={{position: "relative",padding: {md: "50px", xs: "10px"}}}>
        <Box display={"flex"}>
          <Box justifyContent="space-between" sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Link component={Button} onClick={handleRootClick} sx={{cursor: 'default', px: {xs: 1}}}><img src='/assets/te_logo_new.svg'  style={{width: "90px", height: "100%"}} alt="Texas Eclipse" /></Link>
            
          </Box>
          <Box display="flex" width="100%">
            <Link component={Button} onClick={handleRootClick} disableRipple sx={{display: { xs: 'none', md: 'block' },cursor: 'default', py: '10px'}}><img src='/assets/te_logo_new.svg' alt="Texas Eclipse" style={{width: '190px', height: '100%'}} /></Link>
            <Box display="flex" flexDirection="column" width="100%" sx={{display: "block", position: {md: "absolute", xs: "relative"}, top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
              <Typography variant='h1' align='center' sx={{fontSize: {md: "2.625rem", xs: "1rem"}, fontFamily: "Sweet Sans Pro Heavy", fontWeight: "900", color: "#fff",}}>Texas Eclipse Onchain</Typography>
              <Typography variant='body1' align='center' sx={{color: "rgba(255,255,255,0.8)",fontSize: {md: "20px", xs: "0.75rem"}, fontFamily: "Sweet Sans Pro Regular", lineHeight: "normal", fontWeight: "400", mt: {md: "10px", xs: "4px"}}}>The educational hub and social center for the web3 community and the crypto curious.</Typography>
            </Box>
            <Box width="12.5%"></Box>
          </Box>
          {/* <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { xs: 'block', md: 'none' } }}>
              <MenuIcon sx={{color: "white"}}/>
            </IconButton> */}
        </Box>
      </AppBar>
      <Box component="nav">
        <Drawer container={container} variant="temporary" open={mobileOpen} anchor="right" onClose={handleDrawerToggle} ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "black" },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      {
        showLoginModal &&
        <LoginOptionModal open={showLoginModal} handleClose={() => setShowLoginModal(false)} modalTitle="Sign In To View" viewType="view" />
      }

      {
        (user.data.mint_type=='view' && user.data.token=== false && showModal) && 
          <MintedCongrats open={showModal} handleClose={() => setShowModal(false)} type="not_nft"/>
        }
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page
})

const mapDispatchToProps = {
  logoutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNew)