import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";

import { kmsCreateAccount, kmsSignAccount, updateUser } from "../walletCard/index";
import { ahex_to_rba } from "../../../utils";


export async function signUpUser(data, callback) {
  try {
    let user_obj = await axios.post(`${process.env.REACT_APP_BASE_APPLE_URL}/api/google/login`, { ...data, credential: data.credential });
    callback(user_obj.data);
  } catch (e) {}
}

const GoogleAuth = (props) => {
  const { setWalletClicked, walletClicked, signMessage, theme } = props;
  const { setWalletResponse } = props;
  let [loginStatus, setLoginStatus] = useState("Loading...");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState({ type: "", data: {} });

  useEffect(() => {
    if (Object.keys(user.data).length > 0) {
      if (user.data.public_address == null || user.data.public_address == "") {
        setLoginStatus("Creating Account...");
        kmsCreateAccount(user.data, (resp) => {
          if (resp.type == "success") {
            updateUser(
              {
                jwt_token: user.data.token,
                public_address: resp.data.public_address,
                encrypted_buffer: resp.data.buffer_token,
              },
              (resp) => {
                console.log("update user api resp =>", resp);
              }
            );
          } else {
            setWalletClicked("");
            setError(resp.message);
          }
        });
      } else {
        setLoginStatus("Signing Account...");
        kmsSignAccount(
          {
            wallet: user.data.wallet,
            wallet_type: user.data.wallet_type,
            secrete_key: user.data.encrypted_buffer,
            address: user.data.public_address,
            message: signMessage,
          },
          (response) => {
            setWalletClicked("");
            if (response.type == "success") {
              setWalletResponse({
                type: "success",
                data: {
                  event: "sign_user_px",
                  wallet_type: user.data.wallet_type,
                  profile_img: user.data.picture,
                  email: user.data.wallet,
                  eth_address: user.data.public_address,
                  signature: response.signature,
                  fname: user.data?.firstName,
                  lname: user.data?.lastName,
                },
              });
            } else {
              setError(response.message);
            }
          }
        );
      }
    }
  }, [user.data]);

  const handleGoogle = (data) => {
    const signup = signUpUser(data, (response) => {
      if (response.type === "success") {
        setUser(response);
      } else if (response.type === "error") {
        setWalletClicked("");
      }
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleGoogle(codeResponse);
    },
    onError: () => {
      console.log("Login Failed");
    },
    onNonOAuthError: () => {
      setWalletClicked("");
    },
    flow: "auth-code",
  });

  return (
    <Box
      sx={{ p: "15px", borderRadius: "7px", border: "1px solid #000", my: "20px", cursor: walletClicked != "" ? "default" : "pointer", opacity: walletClicked != "" ? "0.6" : "1", background: walletClicked == "Google" && `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)`, "&:hover": { background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)` } }}
      onClick={(e) => {
        if (walletClicked == "") {
          setWalletClicked("Google");
          googleLogin();
        }
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={`/icons/google-icon.svg`} alt={`google icon`} />
            <Typography variant="h5" className="font-gs-md" sx={{ ml: "10px", fontSize: "1rem", lineHeight: "normal", color: "#000" }}>
              Google
            </Typography>
          </Box>
          {walletClicked == "Google" && <div className="spinner" />}
        </Box>
      </Box>
    </Box>
  );
};

export default GoogleAuth;
