import { combineReducers } from 'redux';

import {pageAreaReducer} from './list';
import {userReducer} from './user';

const rootReducer = combineReducers({
    user: userReducer,
    page: pageAreaReducer
});

export default rootReducer;