import { Box, Button, Card, Typography, Link as CustomLink } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function HomeBox(props) {
    const {allowMinting, twitter_link, discord_link, button_text, disabled_btn, desc_text, link_desc, details, text_details, text_link, button_icon, button_click, telegram_link} = props;

  return (
    <Card sx={{background: "linear-gradient(0deg, rgba(255, 255, 243, 0.15) 0%, rgba(255, 255, 243, 0.15) 100%), rgba(0, 0, 0, 0.50)", border: "1px solid rgba(255, 255, 255, 0.25)", borderRadius: "16px", padding: "35px"}}>
        <Button variant='contained' onClick={() => {
            !allowMinting && button_click("Mint Your Free NFT");
        }} fullWidth sx={{cursor: disabled_btn ? "default" : "pointer", opacity: disabled_btn ? "0.3" : "1",background: "#E7E9B4", fontSize: "1.25rem", lineHeight: "normal", fontFamily: "Sweet Sans Pro Bold", fontWeight: "700", color: "#000", p: "15px 40px", borderRadius: "8px", "&:hover": {background: "#E7E9B4"}}}>{button_icon != "" && <img src={button_icon} style={{marginRight: "10px"}}/>}{button_text}</Button>

        <Typography variant='body2' sx={{color: "rgba(255,255,255,0.8)",fontSize: "14px", fontFamily: "Sweet Sans Pro Regular", fontWeight: "400", mt: "10px"}} align='center'>
            {
                desc_text === 'Sign up for the waitlist' ?
                <CustomLink href='https://app.deform.cc/form/cd80e601-3648-4e48-98a9-017f3f5dff49/' target='_blank' color="#fff" sx={{textDecorationColor: "#fff"}}>{desc_text}</CustomLink>
                :
                desc_text
            }
        {link_desc != "" && <span style={{color: "inherit", textDecoration: "underline", cursor: disabled_btn ? "default" : "pointer"}} onClick={() => { !allowMinting && button_click("Sign In To View"); }}>Log in</span>}</Typography>

        <Typography variant='h6' sx={{color: "rgba(255,255,255,0.8)", my: "20px", lineHeight: "normal", fontSize: "1.25rem", fontWeight: "400"}}>{details} <CustomLink href={text_link} target="_blank" sx={{color: "inherit", textDecorationColor: 'inherit'}}>{text_details}</CustomLink>.</Typography>

        {(twitter_link != "" || discord_link != "") &&
            <Box display={"flex"} justifyContent={"space-between"} sx={{flexDirection: {xs: "column", md: "row"}}}>
                <Button onClick={() => {
                    window.open(twitter_link)
                }} variant='contained' sx={{p: "15px 20px", my:1,textDecoration: "underline", borderRadius: "4px", width: {md: "30%", xs: "100%"}, color: "rgba(255,255,255,0.8)", fontWeight: "400", fontSize: "14px", fontFamily: "Sweet Sans Pro Bold", fontWeight: "700", background: "rgba(231, 233, 180, 0.10)",lineHeight: "normal", "&:hover":{background: "rgba(231, 233, 180, 0.10)", textDecoration: "underline"}}}><img src='/assets/x-icon.svg' style={{marginRight: "7px"}}/>Follow Us</Button>

                <Button onClick={() => {
                    window.open(discord_link)
                }} variant='contained' sx={{p: "15px 20px", my:1,textDecoration: "underline", borderRadius: "4px", width: {md: "30%", xs: "100%"}, color: "rgba(255,255,255,0.8)", fontWeight: "400", fontSize: "14px", fontFamily: "Sweet Sans Pro Bold", fontWeight: "700",lineHeight: "normal", background: "rgba(231, 233, 180, 0.10)", "&:hover":{background: "rgba(231, 233, 180, 0.10)", textDecoration: "underline"}}}><img src='/assets/discord-icon.svg' style={{marginRight: "7px"}}/>Build with us!</Button>

                <Button onClick={() => {
                    window.open(telegram_link)
                }} variant='contained' sx={{p: "15px 20px", my:1,textDecoration: "underline", borderRadius: "4px", width: {md: "30%", xs: "100%"}, color: "rgba(255,255,255,0.8)", fontWeight: "400", fontSize: "14px", fontFamily: "Sweet Sans Pro Bold", fontWeight: "700",lineHeight: "normal", background: "rgba(231, 233, 180, 0.10)", "&:hover":{background: "rgba(231, 233, 180, 0.10)", textDecoration: "underline"}}}><img src='/assets/tg-icon.svg' style={{marginRight: "7px"}}/>Get involved</Button>
            </Box>
        }
    </Card>
  )
}

export default HomeBox