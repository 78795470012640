import React, { useState, useEffect } from "react";
import axios from "axios";
import AppleLogin from "react-apple-login";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { kmsCreateAccount, kmsSignAccount, updateUser } from "./index";
import { ahex_to_rba } from "../../../utils";


export function appleSignup(data, callback) {
  return async (dispatch, getState) => {
    let user_obj = await axios.post(`${process.env.REACT_APP_BASE_APPLE_URL}/api/apple/login`, { ...data, redirect_uri: `${process.env.REACT_APP_BASE_API_URL}/api/apple/login` });
    callback(user_obj.data);
  };
}

const AppleAuth = (props) => {
  const {setWalletClicked, walletClicked, signMessage, theme} = props 

  const { setWalletResponse } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  let [loginStatus, setLoginStatus] = useState("Loading...");
  const [error, setError] = useState("");
  const [user, setUser] = useState({ type: "", data: {} });

  // console.log("user.data =>",user.data)
  useEffect(() => {
    if (Object.keys(user.data).length > 0) {
      if (user.data.public_address == null || user.data.public_address == "") {
        setLoginStatus("Creating Account...");
        console.log(" user.data", user.data);
        kmsCreateAccount(user.data, (resp) => {
          if (resp.type == "success") {
            updateUser(
              {
                jwt_token: user.data.token,
                public_address: resp.data.public_address,
                encrypted_buffer: resp.data.buffer_token,
              },
              (resp) => {
                console.log("update user api resp =>", resp);
                // setUser(resp)
              }
            );
          } else {
            setLoading(false);
            setError(resp.message);
          }
        });
      } else {
        setLoginStatus("Signing Account...");
        kmsSignAccount(
          {
            wallet: user.data.wallet,
            wallet_type: user.data.wallet_type,
            secrete_key: user.data.encrypted_buffer,
            address: user.data.public_address,
            message: signMessage,
          },
          (response) => {
            setLoading(false);
            if (response.type == "success") {
              setWalletResponse({
                type: "success",
                data: {
                  event: "sign_user_px",
                  wallet_type: user.data.wallet_type,
                  profile_img: user.data.picture,
                  email: user.data.wallet,
                  eth_address: user.data.public_address,
                  signature: response.signature,
                  fname: user.data?.firstName,
                  lname: user.data?.lastName,
                },
              });
            } else {
              setError(response.message);
            }
          }
        );
      }
    }
  }, [user.data]);
  let appleResponse = (response) => {
    
    if (!response.error) {
      dispatch(
        appleSignup(response, (res) => {
          if (res.type === "success") {
            setUser(res);
          } else if (res.type === "error") {
            setError(res.message);
          }
        })
      );
    }
    
    if (JSON.parse(JSON.stringify(response.error))?.error) {
      setWalletClicked("");
    }
  };
  return (
    <AppleLogin
      clientId="com.polyientx.auth.client"
      redirectURI={`${process.env.REACT_APP_BASE_API_URL}/api/apple/login`}
      usePopup={true}
      callback={appleResponse} // Catch the response
      scope="email name"
      responseMode="query"
      render={(
        renderProps //Custom Apple Sign in Button
      ) => (
        <Box
          sx={{ p: "15px", borderRadius: "7px", border: "1px solid #000", my: "20px", cursor: walletClicked != "" ? "default" : "pointer", opacity: walletClicked != "" ? "0.6" : "1", background: walletClicked == "Apple" && `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)`, "&:hover": {background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.1)`}}}
          onClick={(e) => {
            if(walletClicked == ""){
              setWalletClicked("Apple")
              renderProps.onClick(e);
            }
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{width: "100%"}}>
              <Box sx={{ display: "flex", alignItems: "center" }}>

                <img src={`/icons/apple-icon.svg`} alt={`apple icon`} />
                <Typography variant="h5" className="font-gs-md" sx={{ ml: "10px", fontSize: "1rem", lineHeight: "normal", color: "#000" }}>
                  Apple
                </Typography>
              </Box>

              {walletClicked == "Apple" &&
                <div className='spinner'/>
              }

            </Box>
          </Box>
        </Box>
      )}
    />
  );
};

export default AppleAuth;
