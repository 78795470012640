import React, {useState, useEffect} from 'react'
import { Card, Typography, Box, Alert, IconButton, Link, FormHelperText } from '@mui/material'

import CloseIcon from "@mui/icons-material/Close";
import WalletCard from '../walletCard';
import { useSelector } from 'react-redux';

function WalletArea(props) {
  const {setStep, headerImg, title, wallets, theme, signMessage, walletResponse, setWalletResponse, walletClicked, setWalletClicked, inputVal, setInputVal, setOtpData} = props;
  const {webInfo} = useSelector((state) => state)
  const [openedLoginT, setOpenedLoginT] = useState("Phone");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  
  useEffect(() => {
    if(!wallets.includes("Phone")){
      setOpenedLoginT("Email")
    }
  }, [])

  const walletData = [
    {
      name: "Phone",
      icon: "phone",
      step: "1",
      allowClick: true
    },
    {
      name: "Email",
      icon: "email",
      step: "2",
      allowClick: true
    },
    {
      name: "Google",
      icon: "google",
      step: "2",
      allowClick: false
    },
    {
      name: "Apple",
      icon: "apple",
      step: "2",
      allowClick: false
    },
    {
      name: "Iframe",
      icon: "",
      step: "2",
      allowClick: false
    },
    {
      name: "Web3 wallets",
      icon: "wallet-c",
      step: "3",
      allowClick: false
    }
  ];

  useEffect(() => {
    if (walletResponse.type === 'success') {
      props.handleAuth(walletResponse.data, (res) => {
        console.log("res =>",res)
      })
    }
  },[walletResponse])

  const resetWalletResponse = () => {
    setWalletResponse({type: '', data: {}, message: ''});
  }

  const createCheckoutSession = () => {
    setButtonEnabled(false);
    window.location = `${process.env.REACT_APP_BASE_API_URL}/api/payment/checkout?brand_handle=${webInfo.data.brand_handle}`;
  };

  return (
    <>
      <Card sx={{borderRadius: "15px", border: "1px solid #000", background: "#fff", boxShadow:"0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)", width: "462px", maxWidth: "90vw", padding: {md: "50px 30px", xs: "50px 15px"}, boxSizing: "border-box"}}>
        <Typography variant='h3' sx={{fontSize: "1.25rem", fontFamily: "General Sans bold", mb: "0.5rem", color: "#000"}} align='center'>{title}</Typography>

        {
          headerImg &&
          <Box textAlign="center">
            <img src="/images/aiea/shared_pass.png" width={160} height={237} alt="Pass" />
          </Box>
        }
        
        {walletResponse.type === "werror" && (
          <Box textAlign="left" mb="10px">
            { (
              <FormHelperText error={true} className="font-gs-md" sx={{ color: "#ED1F23 !important", textAlign: "left" }}>
                {walletResponse.message}
              </FormHelperText>
            )}
          </Box>
        )}
        
        {walletData.filter((item) => wallets.includes(item.name)).sort((a, b) => {
            const nameA = a.name;
            const nameB = b.name;
            
            const indexA = wallets.indexOf(nameA);
            const indexB = wallets.indexOf(nameB);

            return indexA - indexB;

        }).map((item, index) => {
            return (
                <WalletCard setOtpData={(res) => setOtpData(res)} inputVal={inputVal} setInputVal={setInputVal} signMessage={signMessage} setWalletClicked={setWalletClicked} walletClicked={walletClicked} theme={theme} keyindex={index} key={index} data={item} setStep={setStep} openedLoginT={openedLoginT} setOpenedLoginT={setOpenedLoginT} handleAuth={props.handleAuth} setWalletResponse={setWalletResponse} walletResponse={walletResponse} />
            )
        })}
        
      </Card>
    </>
  )
}

export default WalletArea