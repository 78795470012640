import React, { useEffect } from "react";
import { connect } from "react-redux";
import {Routes, Route, BrowserRouter, Navigate, useLocation} from "react-router-dom";
import { setPageArea } from "../services/actions";
import {MainAppLayout, NewAppLayout} from "../layouts"
import Home from "./../pages/Home";
import ViewNft from "./../pages/ViewNft";
import HomeNew from "../pages/HomeNew";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop =(_ScrollToTop)

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Layout {...rest} page={rest.page}>
      <Component {...rest} />
    </Layout>
  );
}

export const RoutesObj = (props) => {
  const { user, page } = props;
  
  useEffect(()=>{
    props.setPageArea();
    window.onresize = props.setPageArea;
    return ()=>{
      window.onresize = null;
    }
  },[window.innerWidth]);
  // console.log("page =>", page.width)
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {
            user.login ?
            <>
              <Route exact path="/view-nft" element={<RouteWrapper exact component={ViewNft} layout={MainAppLayout} {...props}/> } />
              <Route exact path="/" element={<RouteWrapper exact component={HomeNew} layout={NewAppLayout} {...props}/> } />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
            :
            <>
              <Route exact path="/" element={<RouteWrapper exact component={HomeNew} layout={NewAppLayout} {...props}/> } />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          }
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page
})

const mapDispatchToProps = {
  setPageArea
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesObj);