import { Box, Button, Card, Typography } from '@mui/material'
import React from 'react'

function DetailCard(props) {
    const {title, desc, link, fullWidth, is_detailed} = props;

    const paraStyle = {fontFamily: "Sweet Sans Pro Regular",my:"20px", fontWeight: "400", fontSize: "1.25rem", color: "rgba(255,255,255,0.8)"}

    const collaboratorAndSpeaker = [
      {name: "Lucas Campbell", designation: "Founder", company: "Pods.xyz "},
      {name: "Evin McMullen", designation: "Co Founder & CEO", company: "Disco.xyz "},
      {name: "Mina Salib", designation: "Head of Web3", company: "Axiom Space "},
      {name: "Matt Cutler", designation: "CEO", company: "Blocknative "},
      {name: "Indi Pollard", designation: "", company: "Blockchain.com "},
      {name: "David Sneider", designation: "Founder", company: "Litprotocol "},
      {name: "James Waugh", designation: "Co-Founder", company: "FireEyes"},
      {name: "Deborah Metts", designation: "Co-Founder", company: "Beyond the Runway"},
      {name: "Matt McKibbin", designation: "", company: "Hyla Funds "},
      {name: "Vinny Lingham", designation: "", company: "Shark Tank South Africa "},
      {name: "A.B. Farrelly", designation: "", company: "Comedian "},
      {name: "Mike Eaton", designation: "", company: "Comedian "},
      {name: "Robbie Heeger", designation: "", company: "Endaoment "},
      {name: "Loxley Fernandes", designation: "", company: "Rug Radio "},
      {name: "David Casey", designation: "", company: "Funding the Commons"},
      {name: "Future Surf Labs", designation: "", company: ""},
      {name: "Jacob Lill ", designation: "", company: ""},
      {name: "ATX DAO ", designation: "", company: ""},
      {name: "Moon DAO", designation: "", company: ""},
      {name: "Illuminati DAO ", designation: "", company: ""},
      {name: "FireEyes DAO ", designation: "", company: ""}
    ]
  return (
    <Card sx={{width: {md: fullWidth? "100%":"48%", xs: "100%"} ,my:1,background: "linear-gradient(0deg, rgba(231, 233, 180, 0.15) 0%, rgba(231, 233, 180, 0.15) 100%), rgba(0, 0, 0, 0.50)", borderRadius: "16px", border: "1px solid rgba(255, 255, 255, 0.25)", p: "35px"}}>
        <Typography variant='h2' align='center' sx={{fontFamily: "Sweet Sans Pro Bold", fontWeight: "900", fontSize: "2.25rem"}}>{title}</Typography>
        {is_detailed ? 
          <Box sx={{mt: "20px"}}>
            <Typography variant='body2' sx={paraStyle}>gm,</Typography>
            <Typography variant='body2' sx={paraStyle}>You’re invited to Texas Eclipse Onchain, the Earth's most unique gathering of humans working in crypto. </Typography>
            <Typography variant='body2' sx={paraStyle}>Current web3-focused collaborators and speakers include:</Typography>

            {collaboratorAndSpeaker.map((c_speakers, index) => {
              return(
                <Box>
                  <Typography variant='h6' sx={{display: "inline-block", fontFamily: "Sweet Sans Pro Bold", fontSize: "1.25rem", fontWeight: "700", color: "rgba(255,255,255,0.8)"}}>{c_speakers.name}</Typography>
                  {c_speakers.designation != "" &&
                    <Typography variant='h6' sx={{display: "inline-block", fontFamily: "Sweet Sans Pro Regular", fontSize: "1.25rem", fontWeight: "400", color: "rgba(255,255,255,0.8)"}}>, {c_speakers.designation}</Typography>
                  }
                  {c_speakers.company != "" &&
                    <Typography variant='h6' sx={{display: "inline-block", fontFamily: "Sweet Sans Pro Regular", fontSize: "1.25rem", fontWeight: "400", color: "rgba(255,255,255,0.8)"}}>, {c_speakers.company}</Typography>
                  }
                </Box>
              )
            })}
            <Typography variant='h6' sx={{display: "inline-block",mt: 1, fontFamily: "Sweet Sans Pro Regular", fontSize: "1.25rem", fontWeight: "400", color: "rgba(255,255,255,0.8)"}}>Among many others! </Typography>
          </Box>
          :
          <>
            <Typography variant='body2' sx={{fontFamily: "Sweet Sans Pro Regular",my:"20px", fontWeight: "400", fontSize: "1.25rem", color: "rgba(255,255,255,0.8)", lineHeight: "normal"}}>{desc}</Typography>

            {link != "" &&
                <Button onClick={() => {
                    window.open(link)
                }} variant='contained' fullWidth sx={{py: "15px", fontFamily: "Sweet Sans Pro Bold", fontWeight: "700", background: "#E7E9B4",color: "#000", lineHeight: "normal",borderRadius: "8px", fontSize: "1.25rem", "&:hover": {background: "#E7E9B4"}}}>Learn More</Button>
            }
          </>
        }
    </Card>
  )
}

export default DetailCard