import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Button,
  Alert,
  Link,
  Container,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";

import { userReset } from "../../services/actions";
import LoginOptionModal from "../../component/modals/login";
import { Navigate, useNavigate } from "react-router-dom";
import { getBalanceInfo } from "../../utils/ethereum";

import { mintNft } from "../../services/actions/ethereum";
import HomeBox from "../../component/homeCard";
import DetailCard from "../../component/detailCard";

const HomeNew = (props) => {
  const { user, page } = props;
  const navigate = useNavigate();
  const [allowMinting, setAllowMinting] = useState(false);
  const [modalTitleVal, setModalTitleVal] = useState("Mint Your Free NFT");
  const [showLoginModal, setShowLoginModal] = useState(false);

  const [mintBalance, setMintBalance] = useState();
  const mintBalanceData = async () => {
    const bal = await getBalanceInfo();
    setMintBalance(bal);
  };
  useEffect(() => {
    mintBalanceData();
  }, []);

  const loginWallet = (titleVal) => {
    console.log("titleVal =>", titleVal);
    setModalTitleVal(titleVal);
    if (user.type == "success") {
      props.mintNft(
        user.data.eth_address,
        user.data.time_stamp,
        user.data.signature,
        user.data.wallet_type,
        "mint"
      );
    } else {
      setShowLoginModal(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          mb: 1,
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url('/assets/main_bg.png'), lightgray -36.069px -191.655px / 108.016% 133.32% no-repeat",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          py: "75px",
          px: 2,
        }}
      >
        {user.type == "request" && user.mintType == "view" ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            width="100%"
            sx={{ height: `calc(90vh - 200px)`, color: "#fff" }}
            alignItems="center"
          >
            Loading your NFT...
          </Box>
        ) : (
          <Container maxWidth={1328} sx={{ padding: "0px !important" }}>
            <Grid container alignItems="start">
              <Grid item xs={12} sm={12} md={12} lg={4.5}>
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{
                    boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.25)",
                    borderRadius: "16px",
                  }}
                >
                  <img
                    src="/assets/banner.png"
                    variant="square"
                    style={{ maxWidth: "90vw", width: "auto", height: "auto", maxHeight: "530px" }}
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6.5}
                sx={{mt: {lg: 0, xs: 3}}}
                ml={page.width >= 995 ? "3.75rem" : 0}
              >
                <Box>
                  <HomeBox
                    telegram_link="https://t.me/texaseclipse24"
                    twitter_link="https://twitter.com/TexasEclipseDAO"
                    discord_link="https://discord.gg/uKKbB9hfFv"
                    button_click={() => {
                      window.open("https://mint.cakeapp.xyz/texas-eclipse");
                    }}
                    button_text="Get Your Co-Creation Token"
                    button_icon=""
                    disabled_btn={false}
                    desc_text=""
                    link_desc=""
                    details="Your free creative GPT to engage with an interactive token onchain giving you credit when your creations are used. "
                    text_details="Learn more here"
                    text_link="https://www.youtube.com/watch?v=vINPShonNyg"
                  />
                </Box>

                {showLoginModal && (
                  <LoginOptionModal
                    open={showLoginModal}
                    handleClose={() => setShowLoginModal(false)}
                    modalTitle={modalTitleVal}
                    viewType="mint"
                    setAllowMinting={setAllowMinting}
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        )}
      </Box>
      <Box
        sx={{
          py: "75px",
          px: 2,
          borderTop: "0px solid rgba(245, 246, 219, 0.20)",
          borderBottom: "2px solid rgba(245, 246, 219, 0.20)",
        }}
      >
        <Container maxWidth="lg" sx={{ padding: "0px !important" }}>
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
            justifyContent="space-between"
          >
            <DetailCard
              is_detailed={false}
              fullWidth={false}
              title="What is Texas Eclipse?"
              desc="Texas Eclipse is a once-in-a-lifetime celebration of music, art, space, and technology during the 2024 total solar eclipse in Burnet, Texas! The festival on April 5-9th, will feature talks and panels from innovators, performances and displays from artists, and unique activations across all disciplines."
              link="https://seetexaseclipse.com/"
            />
            <DetailCard
              is_detailed={false}
              fullWidth={false}
              title="DAO Token Details"
              desc="Earn awards for the event, including merch, festival passes, and unique experiences. A DAO (decentralized autonomous organization), is a community-owned organization that utilizes blockchain technology for coordination, governance, and treasury management."
              link=""
            />
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          px: 2,
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url('/assets/main_bg.png'), lightgray -36.069px -191.655px / 108.016% 133.32% no-repeat",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          py: "75px",
        }}
      >
        <Container maxWidth="lg" sx={{ padding: "0px !important" }}>
          {/* <DetailCard is_detailed={true} fullWidth={true} title="Texas Eclipse Onchain:" desc="Earn awards for the event, including merch, festival passes, and unique experiences. A DAO (decentralized autonomous organization), is a community-owned organization that utilizes blockchain technology for coordination, governance, and treasury management." link=""/> */}
          <Box display="flex" justifyContent="center">
            <img
              src="/assets/te-onchain.webp"
              alt="Texas Eclipse Onchain"
              style={{ maxWidth: "100%", width: "847px", height: "auto" }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page,
});

const mapDispatchToProps = {
  userReset,
  mintNft,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeNew);
