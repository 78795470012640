import React from "react"
import {Modal, Box, Button, Typography, Avatar} from '@mui/material';
import { connect } from "react-redux";


/* const LoginBtn =  styled(Button)(
  ({theme}) => `
  border-width: 2px;
  display: flex;
  alignItems: center;
  &:hover{
    border-width: 2px
  }
  `
); */

const MintedCongrats = (props) => {
  const {open, handleClose, page, type} = props;
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: page.width >= 995 ? 700 : 350,
    bgcolor: 'white',
    py: 0,
    borderRadius:'20px',
    boxShadow: '0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32)'
  };
  
  return(
    <>
      <Modal open={open} sx={{px: {md: type =='not_nft' ? 8 : 12}}} onClose={handleClose} aria-labelledby="login-modal-title" aria-describedby="login-modal-description">
        <Box sx={{...style, px: {md: type =='not_nft' ? 8 : 12, xs: 2}}}>
          {(type =='mint')&&
            <>
              <Typography variant="h1" mt={5} fontSize={page.width >= 995 ? "3rem" : "2rem"} color="#222529" textAlign="center" fontFamily="Sweet Sans Pro Heavy" mb={1}>Congratulations!</Typography>
              <Typography variant="body1" color="#223354" textAlign="center" fontSize="22px" fontFamily="Sweet Sans Pro Bold" mb={3}>You&apos;ve minted a Texas Eclipse 2024 NFT</Typography>
              <Avatar src="/assets/image-grafitti.png" variant="square" sx={{width: "100%", height: "100%"}} />
              <Box sx={{ padding:'20px 0', display:'flex', justifyContent:'center', mt: 2, mb: 3}}>
                <Button variant="contained" onClick={handleClose} sx={{background: "#22185F", fontFamily:"Sweet Sans Pro Bold", boxShadow:"none",px: 5, py: "15px", lineHeight: "normal", color: "white", borderRadius: "8px", borderWidth: '2px', '&:hover': {borderWidth: '2px', background: "#22185F"}}}>View</Button>
              </Box>
            </>  
          }
         {(type =='already_mint') &&
            <>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography variant="h1" mt={5} fontSize="42px" color="#222529" textAlign="center" fontFamily="Sweet Sans Pro Heavy" mb={1}>Welcome Back!</Typography>
                <Typography variant="body1" color="#223354" fontSize="22px" lineHeight="normal" fontFamily="Sweet Sans Pro Bold" mb={1}>You have already minted this NFT.<br/><i>Only 1 per account is allowed.</i></Typography>
              </Box>
              <Box sx={{ padding:'20px 0', display:'flex', justifyContent:'center', mt: 1, mb: 3}}>
                <Button variant="contained" onClick={handleClose} sx={{background: "#22185F", fontFamily:"Sweet Sans Pro Bold", boxShadow:"none",px: 5, py: "15px", lineHeight: "normal", color: "white", borderRadius: "8px", borderWidth: '2px', '&:hover': {borderWidth: '2px', background: "#22185F"}}}>View Your NFT</Button>
              </Box>
            </>
          }

      {(type =='not_nft') &&
            <>
              <Typography variant="h1" mt={5} fontSize="2.625rem" lineHeight="3.125rem" color="#222529" textAlign="center" fontFamily="Sweet Sans Pro Heavy" mb={1}>You don’t have an NFT yet..</Typography>
              <Typography variant="body1" color="#223354" lineHeight="26px" textAlign="center" fontSize="1.375rem" fontFamily="Sweet Sans Pro Regular" mb={3}>Would you like to Mint one for free?</Typography>
              <Box sx={{ padding:'20px 0', display:'flex', justifyContent:'center', mt: 2, mb: 3}}>
                <Button variant="contained" onClick={handleClose} sx={{fontFamily:"Sweet Sans Pro Bold",boxShadow:"none",border:"none",px: 5, py: 1, borderWidth: '2px', '&:hover': {borderWidth: '2px'}}}>Visit The Mint Page</Button>
              </Box>
            </>
          }

          
 
        </Box>
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  page: state.page
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MintedCongrats)