import { Avatar, Box, Button, Container, Typography, Link } from '@mui/material'
import React from 'react'
import DetailCard from '../detailCard'

function Footer(props) {
    const {expanded_footer} = props
  return (
    <Box sx={{my: "75px", background: "#000000"}}>
        <Container maxWidth="lg" sx={{padding: '0px !important'}}>
            {expanded_footer &&
                <DetailCard is_detailed={false} fullWidth={true} title="What is Texas Eclipse Onchain?" desc="Texas Eclipse Onchain is a stage and immersive experience featuring live music, art, and talks from thought leaders within the crypto industry that takes place within the larger Texas Eclipse Festival. Our goal is not only to craft a space for the web3 community to connect, but a welcoming zone for anyone interested in the technology to learn how to engage and receive hands-on onboarding." link=""/>
            }

            {expanded_footer &&
            <Box sx={{pt: expanded_footer ? "50px" : "0"}}>
                <Box display="flex" justifyContent="center">
                    <Button LinkComponent={Link} href="https://twitter.com/TexasEclipseDAO" target="_blank" sx={{m: 0, p: 0}}>
                        <Avatar src="/assets/x.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
                    </Button>
                    <Button LinkComponent={Link} href="https://t.me/texaseclipse24" target="_blank" sx={{m: 0, p: 0}}>
                    <Avatar src="/assets/telegram.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
                    </Button>
                    <Button LinkComponent={Link} href="https://discord.gg/5BqZRFxtKD" target="_blank" sx={{m: 0, p: 0}}>
                    <Avatar src="/assets/discord.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
                    </Button>
                    <Button LinkComponent={Link} href="https://opensea.io/collection/texas-eclipse" target="_blank" sx={{m: 0, p: 0}}>
                        <Avatar src="/assets/opensea.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
                    </Button>
                    <Button LinkComponent={Link} href="https://polygonscan.com/token/0xd82dc82d472b9fefb526df788db59e2a61366277" target="_blank" sx={{m: 0, p: 0}}>
                        <Avatar src="/assets/polygon.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
                    </Button>
                </Box>
            </Box>
            }
    
            <Box sx={{pt: expanded_footer ? "50px" : "0", display: "flex", flexDirection: "column", alignItems: "center", opacity: 0.75}}>
                <Typography variant='body1' sx={{fontFamily: "Sweet Sans Pro Regular",fontWeight: "600", fontSize: "10.101px"}}>Powered by</Typography>
                <Link href="https://step3.io/" target="_blank"><img src="/assets/step3_logo.svg" alt='step3 logo'/></Link>
            </Box>
            
        </Container>
    </Box>
  )
}

export default Footer