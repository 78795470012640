import React, { useEffect, useState } from 'react'
import { Box, Card, Typography } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SpecificVerify from './specificVerify';

function VerifyWithCode(props) {
  const {theme, inputVal, setInputVal, walletClicked, setWalletClicked, setStep, setOtpData, otpData, signMessage, setWalletResponse, walletResponse} = props
  const [isloading, setIsloading] = useState(false);

  useEffect(() => {
    if (walletResponse.type === 'success') {
      props.handleAuth(walletResponse.data, (res) => {
        if (res.type === 'error'){
          setWalletClicked(""); setInputVal(""); setStep("1");
          setWalletResponse({...res, type: 'werror'})
        }
      })
    }
  },[walletResponse]);

  const allowBack = () => {
    if (!isloading){
      setWalletClicked("");
      setInputVal("");
      setStep("1");
    }
  }

  return (
    <Card sx={{borderRadius: "15px", border: "1px solid #000", background: "#fff", boxShadow: "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",  width: "462px",maxWidth: "90vw", boxSizing: "border-box"}}>
      <Box sx={{p: {md: "20px 40px", xs: "20px"}, borderBottom: "1px solid rgba(0, 0, 0, 0.10)"}}>
        <Box display="flex" alignItems="center">
          <KeyboardArrowLeftIcon sx={{color: "#000", cursor: "pointer"}} onClick={allowBack}/>
          <Typography variant='h4' align='center' sx={{pr: '20px',width: "100%", color: "#000",fontSize: "1rem", lineHeight: "normal"}} className='font-gs-sb'>Verify your {walletClicked === 'Phone' ? 'phone number' : 'Email'}</Typography>
        </Box>
      </Box>
      <Box sx={{p: {md: "40px", xs: "50px 20px"}}}>
        <SpecificVerify isloading={isloading} setIsloading={setIsloading} setWalletResponse={setWalletResponse} signMessage={signMessage} setStep={setStep} setWalletClicked={setWalletClicked} setOtpData={setOtpData} otpData={otpData} walletClicked={walletClicked} inputVal={inputVal} setInputVal={setInputVal} theme={theme}/>
      </Box>
    </Card>
  )
}

export default VerifyWithCode