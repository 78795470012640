import React, {Suspense,StrictMode} from "react";
import { Provider } from "react-redux";
import { stopReportingRuntimeErrors } from "react-error-overlay";
import "./App.css";

import configureStore from "./services/configureStore";
import Routes from "./routes"
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';


const store = configureStore();

if (process.env.REACT_APP_NODE_ENV === "production" ) {
  // stopReportingRuntimeErrors(); // disables error overlays
}

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
          <ThemeProvider theme={theme}>
            <Routes/>
          </ThemeProvider>
        </Suspense>
      </Provider>
    </StrictMode>
  );
}

export default App;
