import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Avatar, Box, Button, List, ListItem, ListItemText, Popover, Typography, styled, Link } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {shortEthAddress, shortEmailAddress, shortenPhoneNumber} from "../../utils/ethereum"
import { logoutUser } from '../../services/actions';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding-left: 1rem;
    padding-right: 0;
    color: rgba(255,255,255,0.7);

    &:hover {
      color: rgba(255,255,255,1);
      background: none;
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: rgba(0,0,0,1);
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0px 2rem};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: 1rem;
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: 400;
    color: #9EA4C1;
    display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
    color: #9EA4C1
  `
);

function HeaderUserbox(props) {
  const {user} = props;
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    props.logoutUser();
  };

  const CustomPopover = styled(Popover)({
    '& .MuiBox-root.css-7ilvzl': {
      padding: '0%'
    },
    '& .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper': {
      borderRadius: '0%'
    },
    '& .MuiList-root.MuiList-padding': {
      width: '100%'
    },
    '& .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters:hover': {
      background:  'none'
    }
  });
  return (
    <Box component="span" sx={{display: { xs: 'none', sm: 'inline-block' }}}>
      <UserBoxButton disableRipple={true} ref={ref} >
        <Avatar alt="John" src={user.data?.profile_img == null ? "/assets/profile_img.png" : user.data.profile_img} variant="square" onClick={handleOpen} sx={{borderRadius: '5px'}} />
        <Box component="span" sx={{display: { xs: 'none', md: 'inline-block' }}} onClick={handleOpen}>
          <UserBoxText>
            <UserBoxLabel variant="body1" color="#9EA4C1">{ user.data?.email == null ? (user.data.phone !== null && user.data.wallet_type === 'mobile') ? shortenPhoneNumber(user.data.phone) : shortEthAddress(user.data.eth_address) : shortEmailAddress(user.data.email)}</UserBoxLabel>
          </UserBoxText>
        </Box>
          <KeyboardArrowDownIcon onClick={handleOpen}/>
      </UserBoxButton>

      
      {user.login &&
      <CustomPopover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 200,backgroundColor: '#000000', borderRadius:'0%', p:0 }} display="flex">
          <List sx={{}} component="nav">
            <ListItem onClick={handleLogout} sx={{cursor: 'pointer'}}>
              <ListItemText primary="Log Out" sx={{color: '#fff'}} align="center" />
            </ListItem>
          </List>
        </MenuUserBox>
      </CustomPopover>
      }
    </Box>
  );
}



const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  logoutUser
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox)
