const initialState = {
}

export function pageAreaReducer(state = initialState, action){
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "PAGE_SET_AREA":
      return action.data;
  }
  return state;
}