import {
  connectMetamask,
  connectWallet,
  connectWalletv2,
  connectCoinbase,
  web3signature,
  getTokenIdOfUser,
} from "../../utils/ethereum";
import { loginUser } from "./api";

export function userCustomLogin(
  eth_address,
  signature,
  profile_img,
  email_address,
  mintType,
  userdata
) {
  return async (dispatch) => {
    let currentTime = new Date().getTime();
    let wallet = "custom_wallet";
    dispatch({
      type: "LOGIN_REQUEST",
      providerType: wallet,
      mintType: mintType,
    });
    let data = { eth_address, signature };
    const nftBalance = await getTokenIdOfUser(eth_address);
    let apiData = await loginUser({
      eth_address: eth_address,
      time_stamp: currentTime,
      signature: signature,
      wallet_type: wallet,
      mintType: mintType,
      nftBalance,
    });
    if (apiData.type == "success") {
      let updated_data = { ...apiData.data };
      updated_data.profile_img = profile_img;
      updated_data.email = email_address;
      dispatch({
        type: "LOGIN_SUCCESS",
        data: {
          ...apiData.data,
          email: email_address,
          profile_img,
          time_stamp: currentTime,
          signature: signature,
          wallet_type: wallet,
          mintType: mintType,
          nftBalance,
        },
        network: data.network,
        provider: wallet,
        mintType: mintType,
      });
    } else {
      dispatch({ type: "LOGIN_ERROR", message: apiData.message });
    }
  };
}

export function mintNft(eth_address, currentTime, signature, wallet, mintType, phone_num=null) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST", mintType: mintType });
    const nftBalance = await getTokenIdOfUser(eth_address);
    let apiData = await loginUser({
      eth_address: eth_address,
      time_stamp: currentTime,
      signature: signature,
      wallet_type: wallet,
      mintType: mintType,
      nftBalance,
    });
    if (apiData.type == "success") {
      dispatch({
        type: "LOGIN_SUCCESS",
        data: {
          ...apiData.data,
          time_stamp: currentTime,
          wallet_type: wallet,
          signature: signature,
          phone: phone_num
        },
        provider: wallet,
        mintType: mintType,
      });
    } else {
      dispatch({ type: "LOGIN_ERROR", message: apiData.message });
    }
  };
}

export function userWalletAuth(data, mintType) {
  return async (dispatch) => {
    dispatch({ type: "LOGIN_REQUEST", providerType: data.wallet_type });

    try {
      let currentTime = new Date().getTime();
      let wallet = data.wallet_type == "wallet" ? "wallet" : data.wallet_type === "mobile" ? "mobile" : "metamask";
      /* let apiData = await loginUser({ phone: data?.phone, eth_address: data.eth_address, time_stamp: currentTime, signature: data.signature, resendEmail: false, wallet_type: wallet });
      if (apiData.type == "success") {
        window.localStorage.setItem("login_data", JSON.stringify({ network: data.network, provider: wallet }));
        dispatch({ type: "LOGIN_SUCCESS", data: { ...apiData.data, time_stamp: currentTime, wallet_type: wallet }, network: data.network, provider: wallet });
      } else {
        dispatch({ type: "LOGIN_ERROR", message: apiData.message });
      } */


      mintNft(
        data.eth_address,
        currentTime,
        data.signature,
        wallet,
        mintType,
        data.phone
      )(dispatch);
    } catch (e) {
      dispatch({ type: "LOGIN_ERROR", message: e.message });
    }
  };
}

export function userLogin(type, mintType, resendEmail = false) {
  return (dispatch) => {
    let connect;
    if (type === "wallet") {
      // connect = connectWallet
      connect = connectWalletv2;
    } else if (type == "coinbase") {
      connect = connectCoinbase;
    } else {
      connect = connectMetamask;
    }
    dispatch({ type: "LOGIN_REQUEST", providerType: type, mintType: "" });

    connect(
      process.env.REACT_APP_CHAIN_ID,
      async (obj) => {
        if (obj.type == "success") {
          const data = obj.data;
          let currentTime = new Date().getTime();
          let message = `Welcome to Texas Eclipse 2024!\n\nSigning this message will not trigger a blockchain transaction, cost fees or allow us to transfer contents from your wallet.`;
          web3signature(
            message,
            data.eth_address,
            async (webObj) => {
              if (webObj.type == "success") {
                try {
                  let wallet = type == "wallet" ? "wallet" : "metamask";
                  mintNft(
                    data.eth_address,
                    currentTime,
                    webObj.data.signature,
                    wallet,
                    mintType
                  )(dispatch);
                  // const nftBalance = await getTokenIdOfUser(data.eth_address);
                  // let apiData = await loginUser({eth_address: data.eth_address, time_stamp: currentTime, signature: webObj.data.signature, wallet_type: wallet,mintType:mintType,nftBalance});
                  // if(apiData.type == 'success'){
                  //   dispatch({type:"LOGIN_SUCCESS",data:{...apiData.data,time_stamp:currentTime,wallet_type:wallet},network:data.network,provider:wallet})
                  // }else{
                  //   dispatch({type:"LOGIN_ERROR",message:apiData.message});
                  // }
                } catch (e) {
                  dispatch({ type: "LOGIN_ERROR", message: e.message });
                }
              } else if (webObj.type == "error") {
                dispatch({ type: "LOGIN_ERROR", message: webObj.message });
              } else {
                dispatch({ type: "LOGIN_ERROR", message: webObj.message });
              }
            },
            type === "wallet" ? "other" : "personal"
          );
        } else {
          dispatch({ type: "LOGIN_ERROR", message: obj.message });
        }
      },
      dispatch
    );
  };
}

export function userReset() {
  return (dispatch) => {
    dispatch({ type: "LOGIN_RESET" });
  };
}
