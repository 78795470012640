import { DockOutlined } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ValidateEmail, ahex_to_rba, validatePhone } from "../../../../utils";
import { MuiOtpInput } from "mui-one-time-password-input";
import { walletCreateOtp, walletVerifyOtp } from "../..";
import { kmsCreateAccount, kmsSignAccount, updateUser } from "../../walletCard";
import { useSelector } from "react-redux";

export function matchIsNumeric(text) {
  const isNumber = typeof text === "number";
  const isString = typeof text === "string";
  return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
}

function SpecificVerify(props) {
  const { theme, walletClicked, inputVal, setInputVal, setStep, setWalletClicked, otpData, setOtpData, signMessage, setWalletResponse, isloading, setIsloading } = props;
  const [emailVerify, setEmailVerify] = useState(2);
  const [timerValue, setTimerValue] = useState(0);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [user, setUser] = useState({ type: "", data: {} });
  let [loginStatus, setLoginStatus] = useState("Loading...");

  let isMobile = false;

  let reSendEmailLink = () => {
    setEmailVerify(3);
    setVerified(false);
    setOtp("");
    let themeinfoData = {
      name: "",
      logo: `${process.env.REACT_APP_BASE_API_URL}/assets/te_logo_new.png`,
      bg_color: theme.data.base_pm_color,
      txt_color: theme.data.base_pm_text_color,
      btn_color: theme.data.base_pm_color,
      btn_text_color: theme.data.base_pm_text_color,
    };
    
    const data = { themeInfo: themeinfoData };
    if (walletClicked === "Email") {
      data["email"] = inputVal;
    } else {
      data["mobile"] = `+1 ${inputVal}`;
    }
    setError(false);
    if ((walletClicked === "Email" && ValidateEmail(inputVal)) || (walletClicked === "Phone" && validatePhone(inputVal))) {
      walletCreateOtp(walletClicked === "Email" ? "email" : "mobile", data, (res) => {
        setEmailVerify(4);
        if (res.type === "success") {
          setOtpData(res);
          setStep("2");

          clearTimeout(window.resendTimeout);
          clearInterval(window.timerInterval);
          setTimerValue(60);

          window.resendTimeout = setTimeout(() => {
            clearInterval(window.timerInterval);
            setTimerValue(0);
            setEmailVerify(2);
          }, 60000);

          window.timerInterval = setInterval(() => {
            setTimerValue((prevState) => {
              return prevState - 1;
            });
          }, 1000);
        } else {
          setError(true);
        }
      });
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (Object.keys(user.data).length > 0) {
      if (user.data.public_address == null || user.data.public_address == "") {
        setLoginStatus("Creating Account...");
        kmsCreateAccount(user.data, (resp) => {
          if (resp.type == "success") {
            updateUser(
              {
                jwt_token: user.data.token,
                public_address: resp.data.public_address,
                encrypted_buffer: resp.data.buffer_token,
              },
              (resp) => {
                setUser({...user,data:resp.data});
              }
            );
          } else {
            setError(resp.message);
          }
        });
      } else {
        setLoginStatus("Signing Account...");
        kmsSignAccount(
          {
            wallet: user.data.wallet,
            wallet_type: user.data.wallet_type,
            secrete_key: user.data.encrypted_buffer,
            address: user.data.public_address,
            message: signMessage,
          },
          (response) => {
            if (response.type == "success") {
              const walletdata = {
                wallet_type: user.data.wallet_type,
                profile_img: user.data.picture,
                eth_address: user.data.public_address,
                signature: response.signature,
                fname: user.data?.firstName,
                lname: user.data?.lastName,
              };

              if (user.data.wallet_type === "email") {
                walletdata['email'] = user.data.wallet
              }

              if (user.data.wallet_type === "mobile") {
                walletdata['phone'] = user.data.wallet
              }
              if (user.data.wallet_type !== "mobile") {
                walletdata["event"] = "sign_user_px";
              }

              setWalletResponse({ type: "success", data: walletdata });
            } else {
              setError(response.message);
            }
          }
        );
      }
    }
  }, [user.data]);

  const handleChange = (newValue) => {
    setError(false);
    setOtp(newValue);
  };
  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  const verifyOtpValue = () => {
    setError(false);
    setIsloading(true)
    const data = { code: otp, opt_verification_id: otpData.opt_verification_id };
    walletVerifyOtp(walletClicked === "Email" ? "email" : "mobile", data, (res) => {
      
      if (res.type === "success") {
        setVerified(true);
        setUser(res);
      } else {
        setIsloading(false)
        if (res.message === "Limit of attempts reached") {
          setWalletClicked("");
          setInputVal("");
          setStep("1");
          setWalletResponse({ type: "error", message: res.message });
        } else {
          setError(true);
        }
      }
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <img src={walletClicked === "Phone" ? "/icons/phone-icon.svg" : "/icons/email-icon.svg"} alt={`${walletClicked} icon`} width={30} />
      </Box>
      <Typography variant="h4" align="center" my={"10px"} sx={{ color: "#000", fontSize: "1.25rem", fontFamily: "General Sans bold" }}>
        Enter your code
      </Typography>
      <Typography variant="body1" align="center" my={"10px"} sx={{ color: "#000", fontSize: "13px" }} className="font-gs-md">
        Please check{" "}
        <span className="font-gs-sb" style={{fontFamily: "General Sans Semibold"}}>
          {walletClicked === "Phone" && "+1 "}
          {inputVal}
        </span>{" "}
        for {walletClicked === "Phone" ? "a text message" : "an email"} to find your verification code.
      </Typography>

      <Box mt={"40px"} display="flex" justifyContent="center">
        <MuiOtpInput
          TextFieldsProps={{ autoComplete: false, inputMode:"tel", type: "tel" }}
          gap={isMobile ? 1 : 2.35}
          sx={{
            "& .MuiOutlinedInput-input": { fontSize: "1.563rem", textAlign: "center", p:0, height: '50px', width: '35px' },
            "& .MuiInputBase-root": {
              background: "#ffffff",
              "& .MuiOutlinedInput-notchedOutline": { borderColor: error ? "#ED1F23" : "#000" },
              "&:hover": { background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.10)`, "& .MuiOutlinedInput-notchedOutline": { borderColor: "#000000" } },
              "&:focus-within": { background: "#ffffff", "& .MuiOutlinedInput-notchedOutline": { borderWidth: "3px", borderColor: `${theme.data.base_pm_color} !important` } },
              "&:active": { background: `rgba(${ahex_to_rba(theme.data.base_pm_color)}, 0.10)`, "& .MuiOutlinedInput-notchedOutline": { borderWidth: "3px", borderColor: theme.data.base_pm_color } },
            },
          }}
          value={otp}
          onChange={handleChange}
          length={6}
          validateChar={validateChar}
        />
      </Box>
      {error && (
        <Typography variant="body1" sx={{ mt: "10px", color: "#ED1F23", fontSize: "13px" }} align="center" className="font-gs-md">
          Invalid or expired code
        </Typography>
      )}
      {verified && (
        <Typography variant="body1" sx={{ mt: "10px", color: "#0BB681", fontSize: "1rem" }} align="center" className="font-gs-sb">
          You're Verified!
        </Typography>
      )}
      <Box display={"flex"} justifyContent="center" my="10px">
        <Button variant="contained" disabled={isloading} onClick={() => verifyOtpValue()} sx={{ borderRadius: '10px', p: '8px 20px', fontSize: "1rem", fontFamily: "General Sans Semibold", background: theme.data.base_pm_color, color: theme.data.base_pm_text_color, "&.Mui-disabled": { color: "#fff", background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), ${theme.data.base_pm_color}` }, "&:hover": { background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), var(--fills-primary-primary-fill-100, ${theme.data.base_pm_color})` } }} className="font-gs-sb">
          Submit {isloading && <div className="spinner" />}
        </Button>
      </Box>

      <Box display="flex" flexDirection="row" className="font-gs-md" alignItems="center" justifyContent="center">
        <Typography
          variant="body1"
          onClick={() => {
            emailVerify === 2 && reSendEmailLink();
          }}
          sx={{ textDecoration: "underline", cursor: emailVerify !== 2 ? "not-allowed" : "pointer", color: emailVerify !== 2 ? "#818181" : "#000" }}
          className="font-gs-md"
          align="center"
        >
          Resend
        </Typography>
        {emailVerify == 4 && (
          <Box component="span" className="font-gs-md" sx={{ fontSize: "14px", ml: "2px", color: "#000" }}>
            ({timerValue} Sec.)
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SpecificVerify;
