import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

import SweetSansProRegular from './fonts/SweetSansProRegular.woff';
import SweetSansProHeavy from './fonts/SweetSansProHeavy.woff';
import SweetSansProBold from './fonts/SweetSansProBold.woff';
import SweetSansProMedium from './fonts/SweetSansProMedium.woff';
import GeneralSansBold from './fonts/GeneralSans-Bold.otf'
import GeneralSansMedium from './fonts/GeneralSans-Medium.otf'
import GeneralSansRegular from './fonts/GeneralSans-Regular.otf'
import GeneralSansSemibold from './fonts/GeneralSans-Semibold.otf'
// Create a theme instance.
const theme = createTheme({
  palette: {
    background: {
      default: "#000000"
    },
    text: {
      primary: "#fff"
    },
    primary: {
      main: '#E7E9B4',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    default: {
      main: 'red',
    }
  },
  typography: {
    fontFamily: 'Sweet Sans Pro Regular'
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sweet Sans Pro Regular';
          font-style: normal;
          font-weight: normal;
          src: local('Sweet Sans Pro Regular'), url(${SweetSansProRegular}) format('woff');
        }
        @font-face {
          font-family: 'Sweet Sans Pro Heavy';
          font-style: normal;
          font-weight: normal;
          src: local('Sweet Sans Pro Heavy'), url(${SweetSansProHeavy}) format('woff');
        }
        @font-face {
          font-family: 'Sweet Sans Pro Bold';
          font-style: normal;
          font-weight: normal;
          src: local('Sweet Sans Pro Bold'), url(${SweetSansProBold}) format('woff');
        }
        @font-face {
          font-family: 'Sweet Sans Pro Medium';
          font-style: normal;
          font-weight: normal;
          src: local('Sweet Sans Pro Medium'), url(${SweetSansProMedium}) format('woff');
        }

        @font-face {
          font-family: 'General Sans Semibold';
          font-style: normal;
          font-weight: normal;
          src: local('General Sans Semibold'), url(${GeneralSansSemibold}) format('otf');
        }
        @font-face {
          font-family: 'General Sans Medium';
          font-style: normal;
          font-weight: normal;
          src: local('General Sans Medium'), url(${GeneralSansMedium}) format('otf');
        }
        @font-face {
          font-family: 'General Sans bold';
          font-style: normal;
          font-weight: normal;
          src: local('General Sans bold'), url(${GeneralSansBold}) format('otf');
        }
        @font-face {
          font-family: 'General Sans';
          font-style: normal;
          font-weight: normal;
          src: local('General Sans'), url(${GeneralSansRegular}) format('otf');
        }
      `,
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
          borderBottom: '1px solid rgba(255, 255, 255, 0.15)'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "1328px"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 64,
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 22.45,
          paddingBottom: 22.45,
          textTransform: 'none',
          fontSize: '20px',
          fontFamily: "Sweet Sans Pro Medium"
        },
        outlined: {
          paddingTop: 15,
          paddingBottom: 15,
          paddingLeft: 40,
          paddingRight: 40,
        },
        "&.Mui-disabled": {
          color: '#cacaca',
          backgroundColor: '#071612'
        }
      },
      variants: [
        {
          props: {color: 'secondary'},
          style: {
            borderRadius: '100px',
            backgroundColor: '#ffffff',
            borderColor: '#000000',
            color: "#000000",
            "&:hover": {
              backgroundColor: '#ffffff',
            borderColor: '#000000',
            }
          }
        }
      ]
    }
  }
});

export default theme;
