import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Avatar, Button, Link, Container, Card } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import { connect } from 'react-redux';
import MintedCongrats from '../../component/modals/congrats'
import { getTokenIdOfUser } from '../../utils/ethereum';
import { useLocation } from 'react-router-dom';
import jQuery from "jquery";


const ViewNft = (props) => {
  const { user, page } = props;
  const { state } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [tokenNum, setTokenNum] = useState('');
  
  useEffect(() => {
    setShowModal(state.showModal);
  },[])

  const tokenNumData = async() => {
    const bal = await getTokenIdOfUser(user.data.eth_address);
    setTokenNum(bal)
  }
  useEffect(()=>{
    window.$ = jQuery;
    window.jQuery = jQuery;
    var script = document.createElement('script');
    script.setAttribute('src', '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js');
    script.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(script);

    if(!user.data.token.tokenId) {
      tokenNumData()
    }

    setTimeout(()=>{
      mailchimpFunction();
    },10);
  },[])


  const shareTwitter = ()=>{
    window.open(`https://twitter.com/share?text=Getting excited for the Texas Eclipse 2024 festival and just minted my free NFT! Check it out:&url=${process.env.REACT_APP_DOMAIN_NAME}`);
  }

  const mailchimpFunction = ()=>{
    (function($) {let fnames = ['','','','',''];  let ftypes = ['','','','',''];fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday'; window.fnames=fnames; window.ftypes=ftypes; }(jQuery));var $mcj = jQuery.noConflict(true);
  }

  let mailchimp = ()=>{
    return (
    <div id="mc_embed_signup" style={{color:"#fff",background:'linear-gradient(0deg, rgba(255, 255, 243, 0.15) 0%, rgba(255, 255, 243, 0.15) 100%), rgba(0, 0, 0, 0.50)', border: "1px solid rgba(255, 255, 255, 0.25)", borderRadius: "16px"}}>
    &nbsp; &nbsp; <form action="https://xyz.us21.list-manage.com/subscribe/post?u=4d16f451899d37f0b984309de&id=8d475e007b&f_id=00b3f8e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
       <div id="mc_embed_signup_scroll">
        <Typography variant='h5' sx={{fontSize: "18px", fontFamily: "Sweet Sans Pro Bold", fontWeight: "700"}}>Drop your email below to stay current with event announcements.</Typography>
         {/* <div className="indicates-required"><span className="asterisk">*</span> indicates required</div> */}
        <div className="mc-field-group" style={{margin: "20px 0"}}>
          <label htmlFor="mce-EMAIL">Email Address
          </label>
          <input type="email"  name="EMAIL" className="required email" id="mce-EMAIL" required />
          {/* <span id="mce-EMAIL-HELPERTEXT" className="helper_text" /> */}
        </div>
        <div id="mce-responses" className="clear">
          <div className="response" id="mce-error-response" style={{display: 'none'}} />
          <div className="response" id="mce-success-response" style={{display: 'none'}} />
        </div> {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
         <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_4d16f451899d37f0b984309de_8d475e007b" tabIndex={-1} defaultValue /></div>
        <div className="clear">
        <Button type="submit" variant="contained" disableRipple nolinkstyle="true" sx={{padding: "15px 40px", borderRadius: "8px",fontFamily: "Sweet Sans Pro Bold", border: "2px solid #18342C", lineHeight: "normal","&:hover": {border: "2px solid #18342C"}}}>Subscribe</Button>
        </div>
        &nbsp; &nbsp; </div>
    </form>
  </div>
  )
  }

  return (
    <Box sx={{ py: "75px", background: "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url('/assets/main_bg.png'), lightgray -36.069px -191.655px / 108.016% 133.32% no-repeat", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
      <Container maxWidth="lg" sx={{padding: '0px !important'}}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={5.48}>
          <Box border="1px solid rgba(245, 246, 219, 0.20)" borderRadius="16px" sx={{background: "#000", boxShadow: "0px 0px 20px 0px rgba(255, 255, 255, 0.25)"}} padding="30px">
            <Box display="flex" flexDirection="column">
              <Typography variant="h5" color="#fff" fontSize="2rem" fontFamily="Sweet Sans Pro Heavy" textAlign="center">Your NFT</Typography>
              <Avatar src="/assets/banner.gif" variant="rounded" sx={{my: 2.5, width: page.width >= 995 ? "100%" : "100%", height: "100%"}} />
              {
                user.data.token.tokenId ?
                <Box display="flex" alignItems="center" justifyContent="center"><Avatar src="/assets/polygon-matic-white.png" sx={{width: '28px', height: '28px'}} /> <Typography variant="h5" sx={{fontFamily: "Sweet Sans Pro Bold", fontWeight: "700"}} ml={1}>Texas Eclipse #{user.data.token.tokenId}</Typography></Box>
                :
                  tokenNum &&
                  <Box display="flex" alignItems="center" justifyContent="center"><Avatar src="/assets/polygon-matic-white.png" sx={{width: '28px', height: '28px'}} /> <Typography variant="h5" sx={{fontFamily: "Sweet Sans Pro Bold", fontWeight: "700"}} ml={1}>Texas Eclipse #{tokenNum}</Typography></Box>
              }
            
            </Box>
          </Box>
        </Grid>
      
        <Grid item xs={12} sm={12} md={12} lg={6.52} pl={page.width >= 995 ? "3.75rem" : 0} mt={page.width >= 995 ? 0 : 3}>

        <Card sx={{background: "linear-gradient(0deg, rgba(255, 255, 243, 0.15) 0%, rgba(255, 255, 243, 0.15) 100%), rgba(0, 0, 0, 0.50)", border: "1px solid rgba(255, 255, 255, 0.25)", borderRadius: "16px", padding: "35px"}}>
          {/* <Button variant='contained' onClick={() => {
              button_click(true)
          }} fullWidth sx={{cursor: disabled_btn ? "default" : "pointer", opacity: disabled_btn ? "0.3" : "1",background: "#E7E9B4", fontSize: "1.25rem", fontFamily: "Sweet Sans Pro Bold", fontWeight: "700", color: "#000", p: "15px 40px", borderRadius: "8px", "&:hover": {background: "#E7E9B4"}}}>{button_icon != "" && <img src={button_icon} style={{marginRight: "10px"}}/>}{button_text}</Button> */}

          <Typography variant='h3' align='left' sx={{fontFamily: "Sweet Sans Pro Heavy", fontWeight: "900", color: "#fff"}}>Texas Eclipse 2024</Typography>
          <Typography variant='body2' sx={{color: "rgba(255,255,255,0.8)",fontSize: "1.25rem", fontFamily: "Sweet Sans Pro Regular", fontWeight: "400", my: "20px"}} >Thanks for collecting!</Typography>
          <Typography variant='body2' sx={{color: "rgba(255,255,255,0.8)",fontSize: "1.25rem", fontFamily: "Sweet Sans Pro Regular", fontWeight: "400", my: "20px"}} >With this NFT you've become a member of Texas Eclipse community and you'll be able to access presale tickets and rewards leading up to the event.</Typography>
          <Typography variant='body2' sx={{color: "rgba(255,255,255,0.8)",fontSize: "1.25rem", fontFamily: "Sweet Sans Pro Regular", fontWeight: "400", my: "20px"}} >Please follow us on Twitter, join our Discord and sign up below to receive email updates on what's next!</Typography>

          {/* <Typography variant='h6' sx={{color: "rgba(255,255,255,0.8)", my: "20px", lineHeight: "normal", fontSize: "1.25rem", fontWeight: "400"}}>{details} <Link to={text_link} style={{color: "inherit"}}>{text_details}</Link>.</Typography> */}

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button variant='contained' onClick={shareTwitter}  sx={{lineHeight: "normal",py: "15px", borderRadius: "8px", px: {md: "40px", xs: "20px"}, fontFamily: "Sweet Sans Pro Bold", fontSize: {md: "1.25rem", xs: "1rem"}}}>Share on <img src='/assets/x-icon-black.png' style={{marginLeft: "7px", width: "20px"}}/></Button>

            <Box display="flex">
            <Button LinkComponent={Link} href="https://t.me/texaseclipse24" target="_blank" sx={{m: 0, p: 0, minWidth: "30px !important", marginRight: {xs: "3px", md: "8px"}}}>
              <Avatar src="/assets/telegram.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
            </Button>
            <Button LinkComponent={Link} href="https://discord.gg/5BqZRFxtKD" target="_blank" sx={{m: 0, p: 0, minWidth: "30px !important", marginRight: {xs: "3px", md: "8px"}}}>
              <Avatar src="/assets/discord.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
            </Button>
            <Button LinkComponent={Link} href="https://opensea.io/collection/texas-eclipse" target="_blank" sx={{m: 0, p: 0, minWidth: "30px !important", marginRight: {xs: "3px", md: "8px"}}}>
                <Avatar src="/assets/opensea.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
              </Button>
              <Button LinkComponent={Link} href="https://polygonscan.com/token/0xd82dc82d472b9fefb526df788db59e2a61366277" target="_blank" sx={{m: 0, p: 0, minWidth: "30px !important", marginRight: {xs: "3px", md: "8px"}}}>
                <Avatar src="/assets/polygon.svg" variant="rounded" sx={{width: "38px", height: "100%"}} />
              </Button>
          </Box>
          </Box>
      </Card>
          <Box mt="20px">
          {mailchimp()}
          </Box>
        </Grid>
      </Grid>
      </Container>
      {
        (user.data.mint_type=='new' && showModal) && 
        <MintedCongrats open={showModal} handleClose={() => setShowModal(false)} type="mint"/>
      }
      {
        (user.data.mint_type!='new' && showModal) && 
        <MintedCongrats open={showModal} handleClose={() => setShowModal(false)} type="already_mint"/>
      }
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.page
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewNft)