import React from "react";
import CWalletCard from "./index";
import Web3 from "web3";
import chainObject from "../../../../../services/constants/chains.json"
import { EthereumProvider } from "@walletconnect/ethereum-provider";

const WalletConnect = (props) => {
  const { walletResponse, setWalletResponse, setWalletType, walletType, signMessage } = props;
  const web3 = new Web3();
  let ethereum;
  if (typeof window !== "undefined") {
    ethereum = window.ethereum;
    window.web3Obj = web3;
  }
  const connect = async (callback) => {
    localStorage.clear();
    const projectId = process.env.REACT_APP_WALLET_CONNECT_V2;
    const provider = await EthereumProvider.init({
      projectId: projectId, // REQUIRED your projectId
      chains: [1], // REQUIRED chain ids
      optionalChains: [5, 56, 137, 10, 100], // chains added to optional namespaces
      showQrModal: true, // REQUIRED set to "true" to use @web3modal/standalone,
      // methods, // OPTIONAL ethereum methods
      // events, // OPTIONAL ethereum events
      // rpcMap, // OPTIONAL rpc urls for each chain
      // metadata, // OPTIONAL metadata of your app
      // qrModalOptions, // OPTIONAL - `undefined` by default, see https://docs.walletconnect.com/2.0/web3modal/options
    });

    provider.modal.setTheme({
      themeVariables: {
        "--w3m-font-family": "Roboto, sans-serif",
        "--w3m-accent-color": "#ed2123",
        "--w3m-accent-fill-color": "#ed2123",
        "--w3m-background-color": "#ed2123",
        "--wcm-background-color": "#ed2123",
        "--wcm-accent-color": "#ed2123",
        "--w3m-z-index": "3000",
        "--wcm-z-index": "100000",
      },
    });

    try {
      await provider.connect();
    } catch (e) {
      setWalletType("");
      setWalletResponse({ type: "", data: {}, message: "" });
      return;
    }

    let accounts = await provider.enable();
    web3.setProvider(provider);
    let a = await web3.eth.getAccounts();

    web3.eth.net.getNetworkType().then(async (network_type) => {
      let chain = chainObject.find((v) => v.chainId == provider.chainId);
      let network = {};
      network["type"] = chain.network;
      network["chainId"] = chain.chainId;
      callback({ type: "success", data: { network: network, eth_address: accounts[0] } });
    });

    provider.on("chainChanged", (chainId) => {
      let network = {};
    });
    // accounts changed
    provider.on("accountsChanged", (e) => {
      window.location.reload();
    });
    // session established
    provider.on("connect", (e) => {});
    // session event - chainChanged/accountsChanged/custom events
    provider.on("session_event", (e) => {});
    // connection uri
    provider.on("display_uri", (uri) => {});
    // session disconnect
    provider.on("disconnect", (e) => {
      localStorage.clear();
      window.location.reload();
    });
    window.addEventListener("logoutAll", async function (event) {
      await provider.disconnect();
    });
  };

  const web3signature = async (message, address, callback, type = "personal") => {
    try {
      let signature;
      if (type == "personal") {
        signature = await web3.eth.personal.sign(message, address);
      } else {
        try {
          signature = await web3.eth.sign(message, address);
        } catch (e) {
          signature = await web3.eth.personal.sign(message, address);
        }
      }
      callback({ type: "success", data: { signature } });
    } catch (error) {
      callback({ type: "error", message: error.message });
    }
  };

  const login = async (obj) => {
    setWalletResponse({ type: "request" });
    connect(async (obj) => {
      if (obj.type == "success") {
        const data = obj.data;
        let currentTime = new Date().getTime();
        let message = signMessage;

        web3signature(message, data.eth_address, async (webObj) => {
          setWalletResponse({ type: "success", data: { wallet_type: "wallet-connect", login_type: "member", eth_address: data.eth_address, time_stamp: currentTime, signature: webObj.data.signature } });
        });
      }
    });
  };

  return (
    <CWalletCard
      theme={props.theme}
      data={{ name: "Wallet Connect", walletType: "wallet" }}
      onLogin={() => {
        login();
        setWalletType("wallet");
      }}
      walletResponse={walletResponse}
      wType={walletType}
    />
  );
};

export default WalletConnect;
